import axios from "axios";
import { toast } from "react-toastify";
import {
  AUTH_LOADING,
  AUTH_NOTIFICATION,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  FETCH_PROFILE_FAILED,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_REQUESTED,
  UPDATE_PROFILE_REQUESTED,
  UPDATE_PROFILE_END,
  AUTH_LOGOUT,
  FETCH_PACKAGES,
} from "../types";
import { getPackages } from "../../store/actions/discoverActions";

export const setLoading = (val) => async (dispatch) => {
  dispatch({
    type: AUTH_LOADING,
    payload: val,
  });
};
export const setAuthNotification = (val) => async (dispatch) => {
  dispatch({
    type: AUTH_NOTIFICATION,
    payload: val,
  });
};

export const login = (obj) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    let form = new FormData();
    form.append("action", "login");
    form.append("username", obj.username);
    form.append("password", obj.password);
    form.append("user_type", "b2b_agent");
    axios
      .post("https://bo.discovershurooq.ae/api/pos_api.php", form)
      .then((res) => {
        if (res.data.token) {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data.token,
          });
          dispatch({
            type: FETCH_PROFILE_SUCCESS,
            payload: { name: obj.username },
          });
          dispatch(setAuthNotification("Login Success"));
          window.setTimeout(() => {
            dispatch(setAuthNotification(""));
          }, 2500);
          dispatch(setLoading(false));
        } else {
          dispatch({
            type: LOGIN_FAIL,
          });
          dispatch(setAuthNotification("Invalid Credentials"));
          window.setTimeout(() => {
            dispatch(setAuthNotification(""));
          }, 2500);
          dispatch(setLoading(false));
        }
      });
  } catch (e) {
    // toast.warning(e.message)
    dispatch(setAuthNotification("Login Success"));
    window.setTimeout(() => {
      dispatch(setAuthNotification(""));
    }, 2500);
    dispatch(setLoading(false));
  }
};

export const getUserProfile = (phone, token, onSuccess) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_PROFILE_REQUESTED,
    });
    const form = new FormData();
    form.append("action", "getUser");
    form.append("mobile", phone);
    const { data } = await axios.post(
      "https://bo.discovershurooq.ae/api/pos_api.php",
      form,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!data.data) throw new Error("User profile fetch failed.");
    dispatch({
      type: FETCH_PROFILE_SUCCESS,
      payload: data.data,
    });
    onSuccess && onSuccess();
  } catch (err) {
    dispatch({
      type: FETCH_PROFILE_FAILED,
    });
    toast("Something went wrong, unable to fetch customer data", {
      type: "error",
    });
  }
};

export const updateUserProfile = (id, userData, token) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PROFILE_REQUESTED,
    });
    const form = new FormData();
    form.append("action", "posUpdateUserProfile");
    form.append("id", id);
    if (userData.email?.length) {
      form.append("email", userData.email);
    }
    if (userData.name?.length) {
      form.append("name", userData.name);
    }
    form.append("gender", userData.gender);
    form.append("language", userData.language);
    form.append("nature_info", userData.nature);
    form.append("nature_notes", userData.natureNotes);
    form.append("is_tourist", userData.isTourist);
    form.append("tourist_info", userData.tourist);
    form.append("tourist_notes", userData.touristNotes);
    form.append("is_resident", userData.isResident);
    form.append("resident_info", userData.resident);
    form.append("resident_notes", userData.residentNotes);
    form.append("is_expat", userData.isExpat);
    form.append("expat_info", userData.expat);
    form.append("expat_notes", userData.expatNotes);
    form.append("nature_of_tourist", userData.natureOfTourist);
    form.append("nature_of_tourist_notes", userData.natureOfTouristNotes);
    form.append("is_corporate", userData.isCorporate);
    form.append("corporate_info", userData.corporate);
    form.append("corporate_notes", userData.corporateNotes);
    form.append("is_stakeholder", userData.isStakeHolder);
    form.append("stakeholder_info", userData.stakeHolder);
    form.append("stakeholder_notes", userData.stakeHolderNotes);
    form.append("is_government", userData.isGovernment);
    form.append("government_info", userData.government);
    form.append("government_notes", userData.governmentNotes);
    // for (var pair of form.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
    await axios.post("https://bo.discovershurooq.ae/api/pos_api.php", form, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    toast("Profile updated successfully", {
      type: "success",
    });
    dispatch(getUserProfile(userData.mobile, token));
  } catch (err) {
    toast("Profile update failed", {
      type: "error",
    });
  } finally {
    dispatch({
      type: UPDATE_PROFILE_END,
    });
  }
};
export const logout = () => (dispatch) => {
  dispatch({
    type: AUTH_LOGOUT,
  });
};
export const searchUser = async (mobile, token, onSuccess, onError) => {
  try {
    const form = new FormData();
    form.append("action", "searchUsers");
    form.append("mobile", mobile);

    const { data: userData } = await axios.post(
      "https://bo.discovershurooq.ae/api/pos_api.php",
      form,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    onSuccess && onSuccess(userData.data);
  } catch (err) {
    onError && onError(err);
  }
};

export const getUserDetail = async (user_id, token, onSuccess, onError) => {
  try {
    const form = new FormData();
    form.append("action", "userDetails");
    form.append("id", user_id);

    const { data: userData } = await axios.post(
      "https://bo.discovershurooq.ae/api/pos_api.php",
      form,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    onSuccess && onSuccess(userData.data);
  } catch (err) {
    onError && onError(err);
  }
};

export const favoriteDetail =
  (id, action, favAvailable, token, setFavAvailable) => async (dispatch) => {
    try {
      const form = new FormData();
      form.append("action", action);
      form.append("package_id", id);
      const data = await axios.post(
        "https://bo.discovershurooq.ae/api/pos_api.php",
        form,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFavAvailable(data.data.packageData.favorite === "1");
      toast(
        `Favorite ${
          data.data.packageData.favorite === "1" ? "added" : "removed"
        } successfully`,
        {
          type: "success",
        }
      );
      dispatch(getPackages(token));
    } catch (err) {
      toast(`Favorite ${favAvailable ? "removed" : "added"} failed`, {
        type: "error",
      });
    } finally {
    }
  };

export const getBalanceData = async (token, onSuccess) => {
  const form = new FormData();
  form.append("action", "b2bagent_balance");

  const { data: response } = await axios.post(
    "https://bo.discovershurooq.ae/api/pos_api.php",
    form,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  console.log(response);
  onSuccess && onSuccess(response);
};
