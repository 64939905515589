import React, { useMemo, useState } from "react";
import { Card, CardFooter, CardHeader } from "reactstrap";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/themes/theme-blue.css";
import UserDetails from "./UserDetails";
import { useDispatch, useSelector } from "react-redux";
import CartBodyList from "./CartBodyList";
import { useCookies } from "react-cookie";
import NewCustomerForm from "./NewCustomerForm";
import ApplyCoupon from "./ApplyCoupon";
import BookingModal from "./BookingModal";

import UserProfileHeader from "./UserProfileHeader";
import { useHistory } from "react-router-dom";

const NewCustomer = (props) => {
  return (
    <AwesomeButton {...props} className="btn-primary w-100" size="medium">
      New Customer
    </AwesomeButton>
  );
};

function Cart() {
  const { couponValidityData, userProfile } = useSelector((state) => ({
    userProfile: state.authUser.userDetails,
    couponValidityData: state.discover.couponValidityData,
  }));
  const [isOpen, setIsOpen] = useState(false);
  const [isClearOpen, setIsClearOpen] = useState(false);
  const [isCouponOpen, setCouponOpen] = useState(false);
  const [newFormOpen, setNewFormOpenState] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const toggleNewOpen = () => {
    setNewFormOpenState(!newFormOpen);
  };
  const toggleClearOpen = () => {
    setIsClearOpen(!isClearOpen);
  };
  const toggleCouponOpen = () => {
    setCouponOpen(!isCouponOpen);
  };

  const { token } = useSelector((state) => ({
    token: state.authUser.uid,
    bookingPackageStatus: state.discover.bookingPackageStatus,
  }));

  const dispatch = useDispatch();
  const history = useHistory();
  const [cookies, setCookie] = useCookies(["cart"]);

  const bookAllCart = () => {
    // let _cartPackages = cookies.cart?.packages || [];
    // if (!_cartPackages.length) return;
    // if (!userProfile.id?.length) return;

    // _cartPackages = _cartPackages.map((pack) => {
    // 	if (pack["time_slot_data"]) delete pack["time_slot_data"];
    // 	return pack;
    // });
    // dispatch(
    // 	bookPackages({ ...cookies.cart, packages: _cartPackages }, userProfile.id, token, () => {
    // 		setCookie("cart", { packages: [], coupon: null }, { path: "/" });
    // 		toast.success("Booking successful!!!");
    // 	})
    // );
    history.replace("/checkout");
    // toggleCouponOpen();
  };

  const cartItemCount = useMemo(() => {
    return cookies.cart?.packages?.length || 0;
  }, [cookies.cart?.packages]);

  const total = useMemo(() => {
    if (!cookies.cart?.packages?.length) return 0;
    let _total = 0;
    for (let pack of cookies.cart.packages) _total += pack.total;
    return _total;
  }, [cookies.cart?.packages]);

  const userHasData = useMemo(() => {
    return userProfile.name?.length || userProfile.mobile?.length;
  }, [userProfile.name, userProfile.mobile]);

  const couponDiscountedAmount = useMemo(() => {
    if (!couponValidityData) return 0;
    const deductedTotalValue = couponValidityData
      .map((cpnPkg) => cpnPkg.total || 0)
      .reduce((prev, curr) => prev + curr, 0);
    return Number(deductedTotalValue);
  }, [couponValidityData]);

  return (
    <>
      <div className="cart">
        <NewCustomerForm
          isOpen={newFormOpen}
          toggleNewUserDetails={toggleNewOpen}
        />
        <Card className="cart__card h-100" style={{ overflowY: "hidden" }}>
          <CardHeader className="border-0 text-center header-card">
            <NewCustomer onPress={toggleNewOpen} />
            <UserProfileHeader
              toggleClearOpen={toggleClearOpen}
              toggleOpen={toggleOpen}
            />
          </CardHeader>
          <CartBodyList />
          <CardFooter className="border-0 px-0 pt-4 pb-0 w-100">
            <ApplyCoupon isOpen={isCouponOpen} toggleOpen={toggleCouponOpen} />
            <div className="text-center">
              <AwesomeButton
                disabled={!cartItemCount}
                type="primary"
                className="w-100"
                style={{ height: 60 }}
                onPress={() => bookAllCart()}
              >
                Book Now
                {/* (AED {total - couponDiscountedAmount}) */}
              </AwesomeButton>
            </div>
          </CardFooter>
        </Card>
      </div>
      <BookingModal />
      <UserDetails isOpen={isOpen} toggleUserDetails={toggleOpen} />
    </>
  );
}

export default Cart;
