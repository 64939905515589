import Login from "../auth/Login";
import BookingClose from "../views/BookingClose";
import BookingDetails from "../views/BookingDetails";
import DiscoverMleiha from "../views/DiscoverMleiha";
import FindUser from "../views/FindUser";
import ProductDetails from "../views/ProductDetails";
import PurchaseHistory from "../views/PurchaseHistory";
import RedeemBooking from "../views/RedeemBooking";
import UserDetails from "../views/UserDetails";
import Checkout from "../views/Checkout";
import CreditHistory from "../views/CreditHistory";
import CloseWindow from "../views/CloseWindow";

let routes = [
  {
    path: "/auth/login",
    component: Login,
    layout: "auth",
  },
  {
    path: "/history",
    component: PurchaseHistory,
    layout: "pos",
  },
  {
    path: "/find",
    component: FindUser,
    layout: "pos",
  },
  {
    path: "/checkout",
    component: Checkout,
    layout: "pos",
  },
  {
    path: "/b2bagents",
    component: DiscoverMleiha,
    layout: "pos",
    exact: true,
  },
  {
    path: "/p/:package_slug",
    component: ProductDetails,
    layout: "pos",
  },
  {
    path: "/order-details/:booking_number",
    component: RedeemBooking,
    layout: "pos",
  },
  {
    path: "/user-details/:user_id",
    component: UserDetails,
    layout: "pos",
  },
  {
    path: "/purchase-details/:purchase_number",
    component: BookingDetails,
    layout: "pos",
  },
  {
    path: "/booking-details/**",
    component: BookingClose,
    layout: "pos",
  },
  {
    path: "/credit-history",
    component: CreditHistory,
    layout: "pos",
  },
  {
    path: "/closewindow",
    component: CloseWindow,
    layout: "pos",
  },
];
export default routes;
