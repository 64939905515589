import React, { useState } from "react";
import { Input, Label, Form, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../store/actions/authActions";
import { AwesomeButton } from "react-awesome-button";

function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const { loading, notification } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const onLogin = (e) => {
    e.preventDefault();
    dispatch(login({ username: userName, password: password }));
  };

  const time = () => {
    var today = new Date();
    var curHr = today.getHours();

    if (curHr < 12) {
      return "Good Morning,";
    } else if (curHr < 18) {
      return "Good Afternoon,";
    } else {
      return "Good Evening,";
    }
  };

  return (
    <div className="login__wrapper">
      <div className="login__container">
        <div className="mb-4">
          <img src="https://iili.io/jqdycF.md.jpg" height="120" />
        </div>
        <h2 className="text-center">
          <b>{time()} Welcome Back</b>
        </h2>
        <h3 className="text-center">sign in to continue.</h3>
        <Form onSubmit={onLogin}>
          <Label>Username</Label>
          <Input
            type="text"
            placeholder="your username"
            required
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />

          <Label>Password</Label>
          <Input
            type="password"
            placeholder="*********"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <div className="d-flex justify-content-center w-100">
            <AwesomeButton className="auth-btn mt-3 w-50">
              {loading ? <Spinner size="sm" /> : null} &nbsp; Sign In
            </AwesomeButton>
          </div>

          {notification.length ? (
            <p className="error mx-auto mt-3 mb-0">Invalid Credentials</p>
          ) : null}
        </Form>
        <small>
          Trouble to Login ? Contact <u>sales@shurooq.gov.ae</u>
        </small>
      </div>
      <div>
        <img
          src="https://mms.businesswire.com/media/20160405006350/en/517673/5/Image_of_the_emirate_of_Sharjah.jpg"
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
}

export default Login;
