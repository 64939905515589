import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import PurchaseHistoryTable from "../components/PurchaseHistoryTable";
import { getPurchaseHistory } from "../store/actions/discoverActions";
import { AwesomeButton } from "react-awesome-button";


const PurchaseHistory = ({ purchaseHistory }) => {


 
  const [pHistory, setPHistory] = useState([]);

  const dispatch = useDispatch();

  const token = useSelector((state) => state.authUser.uid);

  useEffect(() => {
    if (purchaseHistory) {
      setPHistory(purchaseHistory);
      return;
    }
    dispatch(
      getPurchaseHistory(token, setPHistory, () => {
        toast.error("Could not load purchase history. Reload.");
      })
    );
    // eslint-disable-next-line
  }, []);

  return (
    <div className="payment__container">
      <h5 className="mt-5 mb-5">Order History</h5>
      <PurchaseHistoryTable purchaseHistory={pHistory} />

      
                 
            

    </div>
  );
};

export default PurchaseHistory;
