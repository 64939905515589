import React, { useMemo, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  Label,
} from "reactstrap";
import { AwesomeButton } from "react-awesome-button";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import CartBodyList from "../components/CartBodyList";
import {
  bookPackages,
  cancelBooking,
  initiatePayment,
  authorizePayLater,
} from "../store/actions/discoverActions";
import UserProfileHeader from "../components/UserProfileHeader";
import ClearCartModal from "../components/ClearCartModal";
import UserDetails from "./UserDetails";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

function Checkout(props) {
  const [cookies, setCookie] = useCookies(["cart"]);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isClearOpen, setIsClearOpen] = useState(false);
  const [bookingMethod, setBookingMethod] = useState("");
  const [notes, setNotes] = useState("");
  const [bookingMethodNotes, setBookingMethodNotes] = useState("");
  const [paymentJSON, setPaymentJSON] = useState([
    { title: "CREDIT", value: "b2b-credits" },
  ]);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const toggleClearOpen = () => {
    setIsClearOpen(!isClearOpen);
  };

  const {
    couponValidityData,
    bookingSequence,
    token,
    userDetails,
    userProfile,
  } = useSelector(({ authUser, discover }) => ({
    token: authUser.uid,
    userDetails: authUser.userDetails,
    bookingSequence: discover.bookingSequence,
    couponValidityData: discover.couponValidityData,
    userProfile: authUser.userDetails,
  }));

  const dispatch = useDispatch();
  const history = useHistory();

  const cartItemCount = useMemo(() => {
    return cookies.cart?.packages?.length || 0;
  }, [cookies.cart?.packages]);

  const total = useMemo(() => {
    if (!cookies.cart?.packages?.length) return 0;
    let _total = 0;
    for (let pack of cookies.cart.packages) _total += pack.total;
    return Number(_total);
  }, [cookies.cart?.packages]);

  const couponDiscountAmount = useMemo(() => {
    if (!couponValidityData?.length) return 0;
    const deductedTotalValue = couponValidityData
      .map((cpnPkg) => cpnPkg.total || 0)
      .reduce((prev, curr) => prev + curr, 0);
    return total - Number(deductedTotalValue);
  }, [couponValidityData, total]);

  const onBookingCancelled = () => {
    dispatch(cancelBooking());
  };

  const onBookingSubmit = () => {
    if (cookies.cart?.packages.length === 0) {
      toast.warn("Please add items to cart", {
        position: "top-center",
      });
    } else if (!paymentMethod) {
      toast.warn("Please choose payment method", {
        position: "top-center",
      });
    } else if (!userProfile.name?.length && !userProfile.mobile?.length) {
      toast.warn("Please add customer", {
        position: "top-center",
      });
    } else {
      dispatch(
        bookPackages(
          notes,
          cookies.cart,
          bookingMethod,
          bookingMethodNotes,
          userDetails.id,
          token,
          (purchaseResponse) => {
            dispatch(
              initiatePayment(
                purchaseResponse,
                token,
                paymentMethod,
                (paymentResponse) => {
                  console.log({ paymentResponse });

                  window.open(
                    paymentResponse.payment_link,
                    "Payment - Discover POS",
                    `left=100,top=100,popup=yes`
                  );

                  toast.success("Order(s) Created. Waiting for Payment.", {
                    position: "top-center",
                  });

                  setCookie(
                    "cart",
                    {
                      packages: [],
                      coupon_id: null,
                    },
                    { path: "/" }
                  );

                  history.push(
                    `/purchase-details/${purchaseResponse.purchase_number}`
                  );
                }
              )
            );
          }
        )
      );
    }
  };

  return (
    <>
      <div className="booking__details mt-3 mb-3">
        <div className="cart__list">
          <CartBodyList />
        </div>
        <div className="booking__summary">
          <div className="content">
            <h4>Booking Summary</h4>
            {/* <UserProfileHeader
              toggleClearOpen={toggleClearOpen}
              toggleOpen={toggleOpen}
            /> */}
            <hr />
            <div className="amount__data">
              <div className="packages__count">
                <span>{cartItemCount > 1 ? "Package(s):" : "Package: "}</span>
                <b>{cartItemCount}</b>
              </div>
              <div className="initial__total">
                <span>Total:</span>
                <b>AED {total}</b>
              </div>
              <div className="net__total">
                <span>Net Total:</span>
                <b>AED {total - couponDiscountAmount}</b>
              </div>
            </div>
          </div>
          <div className="payment-method">
            {paymentJSON.map((payment) => (
              <label className="payment-card">
                <input
                  name="plan"
                  className="radio"
                  type="radio"
                  value={payment.value}
                  onClick={(e) => setPaymentMethod(e.target.value)}
                />

                <span className="payment-details">
                  <span className="payment-type">{payment.title}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="payment-icon"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </label>
            ))}
          </div>
          <Input
            placeholder="Internal Notes"
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value);
            }}
            type="text"
            style={{ marginTop: "1rem" }}
            required
          />
          <AwesomeButton
            onPress={onBookingSubmit}
            className="header_menu__item mt-4 danger"
          >
            Confirm Booking
          </AwesomeButton>
        </div>
      </div>
      <ClearCartModal isOpen={isClearOpen} toggleOpen={toggleClearOpen} />
      {/* <UserDetails isOpen={isOpen} toggleUserDetails={toggleOpen} /> */}
    </>
  );
}

export default Checkout;
