export const AUTH_LOADING = "AUTH_LOADING";
export const AUTH_NOTIFICATION = "AUTH_NOTIFICATION";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const FETCH_PACKAGES = "FETCH_PACKAGES";
export const PACKAGES_FILTERED = "PACKAGES_FILTERED";
export const FETCH_PACKAGE = "FETCH_PACKAGE";
export const COUPON_VALIDITY_SUCCESS = "COUPON_VALIDITY_SUCCESS";
export const COUPON_VALIDITY_FAILED = "COUPON_VALIDITY_FAILED";
export const COUPON_VALIDITY_REQUESTED = "COUPON_VALIDITY_REQUESTED";
export const FETCH_PACKAGE_SLOTS = "FETCH_PACKAGE_SLOTS";
export const FETCH_DATE_AVAILABLE = "FETCH_DATE_AVAILABLE";
export const BOOK_PACKAGE_SUCCESS = "BOOK_PACKAGE_SUCCESS";
export const BOOK_PACKAGE_FAILED = "BOOK_PACKAGE_FAILED";
export const BOOK_PACKAGE_REQUESTED = "BOOK_PACKAGE_REQUESTED";

export const FETCH_PROFILE_SUCCESS = "PROFILE_FETCH_SUCCESS";
export const FETCH_PROFILE_FAILED = "PROFILE_FETCH_FAILED";
export const FETCH_PROFILE_REQUESTED = "PROFILE_FETCH_REQUESTED";

export const UPDATE_PROFILE_REQUESTED = "PROFILE_UPDATE_REQUESTED";
export const UPDATE_PROFILE_END = "UPDATE_PROFILE_END";

export const SWITCH_TAG = "SWITCH_TAG";

export const FETCH_COUPONS_SUCCESS = "FETCH_COUPONS_SUCCESS";
export const FETCH_COUPONS_FAILED = "FETCH_COUPONS_FAILED";
export const FETCH_COUPONS_REQUESTED = "FETCH_COUPONS_REQUESTED";

export const COUPON_SKIPPED = "COUPON_SKIPPED";
export const BOOKING_FINISHED = "BOOKING_FINISHED";
export const BOOKING_CANCELLED = "BOOKING_CANCELLED";

export const PAYMENT_REQUESTED = "PAYMENT_REQUESTED";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_FAILED = "PAYMENT_FAILED";
