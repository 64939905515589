import React, { useState } from "react";
import { AwesomeButton } from "react-awesome-button";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Input, Label, Modal, ModalBody } from "reactstrap";

const RedeemOrderModal = ({ isOpen, toggleOpen }) => {
	const [orderNumber, setOrderNumber] = useState("");

	const history = useHistory();

	const onViewOrder = () => {
		if (!orderNumber.length) {
			toast.error("Enter order number.", {
				position: "top-center",
			});
			return;
		}
		toggleOpen();
		history.push("/order-details/" + orderNumber.toUpperCase());
	};

	return (
		<Modal centered isOpen={isOpen} toggle={toggleOpen} size="md">
			<ModalBody className="px-5 py-4">
				<h4 className="mb-4">Open Order Details to Redeem</h4>

				<Label>Order Number</Label>
				<Input placeholder="XXXXXX" value={orderNumber} onChange={(e) => setOrderNumber(e.target.value)} />

				<div className="d-flex gap-2 cart__clear-buttons">
					<AwesomeButton onPress={onViewOrder} className="mt-3 w-100">
						View Order Details
					</AwesomeButton>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default RedeemOrderModal;
