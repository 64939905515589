import React from "react";
import DataTable from "react-data-table-component";
import { AwesomeButton } from "react-awesome-button";
import { useHistory } from "react-router-dom";

const userSearchTableModel = (onDetailSeek) => [
	{
		name: "Mobile Number",
		cell: (row) => row.mobile,
	},
	{
		name: "Email",
		cell: (row) => row.email || <i>Not Set.</i>,
		center: true,
	},
	{
		name: "",
		cell: (row) => (
			<div className="pt-2 pb-2">
				<AwesomeButton onPress={() => onDetailSeek(row)} size="small">
					Details
				</AwesomeButton>
			</div>
		),
		right: true,
	},
];

const UserSearchTable = ({ userList, status }) => {
	const history = useHistory();

	const onDetailSeek = (row) => {
		history.push("/user-details/" + row.id);
	};

	return (
		<div className="order__data mt-5">
			<DataTable progressPending={status === "requested"} columns={userSearchTableModel(onDetailSeek)} data={userList} />
		</div>
	);
};

export default UserSearchTable;
