import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCreditHistory } from "../store/actions/discoverActions";

const CreditHistory = () => {
  const token = useSelector((state) => state.authUser.uid);
  const [creditHistory, setCreditHistory] = useState();
  useEffect(() => {
    getCreditHistory(token, (response) => {
      console.log(response);
      setCreditHistory(response);
    });
  }, []);
  return (
    <div className="order__data mt-5">
      <div className="mt-3 mb-3 w-100 d-flex justify-content-between align-items-center">
        <h4 className="credit__title">Credit History</h4>
      </div>
      {creditHistory && (
        <div
          className="credit__history"
          dangerouslySetInnerHTML={{ __html: creditHistory }}
        ></div>
      )}
    </div>
  );
};

export default CreditHistory;
