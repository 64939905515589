import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { getPackages, selectTag } from "../store/actions/discoverActions";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/themes/theme-blue.css";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

function DiscoverMleiha() {
  const dispatch = useDispatch();
  const [cookies, setCookies] = useCookies("");
  // const [packages, setPackages] = useState([]);
  // const [tags, setTags] = useState([]);
  const { token } = useSelector(({ authUser }) => ({
    token: authUser.uid,
  }));
  const { packages, filterTerm, activeTag, tags } = useSelector(
    (state) => state.discover
  );

  useEffect(() => {
    if (!packages.length) {
      dispatch(getPackages(token));
    }
    // eslint-disable-next-line
  }, [packages]);

  const history = useHistory();

  const tagFilteredPackages = useMemo(() => {
    if (!activeTag.length) return packages;
    if (activeTag === "favorite") {
      return packages.filter((_pack) => _pack.favorite);
    } else {
      return packages.filter((_pack) =>
        _pack.tags.split(",").includes(activeTag)
      );
    }
  }, [activeTag, packages]);

  const filteredPackages = useMemo(() => {
    if (!filterTerm.length) return tagFilteredPackages;

    const regSearch = new RegExp(filterTerm, "i");
    return tagFilteredPackages.filter((_package) => {
      return _package.package_name.search(regSearch) >= 0;
    });
  }, [filterTerm, tagFilteredPackages]);

  const changeTag = (tagName) => {
    dispatch(selectTag(tagName));
  };

  return (
    <>
      <Container className="mt-4 mb-5 pb-5">
        <Row>
          {filteredPackages.length > 0 &&
            filteredPackages.map((pack, index) => {
              return (
                pack.package_name !== "" && (
                  // pack.destination_id !== "1" &&
                  <Col key={index} lg="4" md="6" className="mt-3">
                    <div
                      className="packages-card"
                      onClick={() => history.push(`/p/${pack.url_slug}`)}
                    >
                      <div className="wrapper">
                        <img
                          className="render-image"
                          src={`https://bo.discovershurooq.ae/cdn-cgi/image/width=680,height=400,quality=100/files/${pack.img}`}
                          alt={pack.package_name}
                        />
                        <span className="dark-layer" />
                        <div className="content">
                          <div>
                            <h3 className="title">{pack.package_name}</h3>
                          </div>
                          {/* <div className="price-package">
                            <div>
                              {pack.child_price && (
                                <h3 className="price">
                                  AED {pack.child_price}
                                </h3>
                              )}
                            </div>
                            <div>
                              {pack.adult_price && (
                                <h3 className="price">
                                  AED {pack.adult_price}
                                </h3>
                              )}
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </Col>
                )
              );
            })}
        </Row>
      </Container>
      {/* <Container className="tag__footer">
        <div className="tags__wrapper">
          <div className="tags__container">
            <div
              className={`tag__item ${!activeTag.length ? "active" : ""}`}
              onClick={() => changeTag("")}
            >
              All
            </div>
            <div
              className={`tag__item ${activeTag === "popular" ? "active" : ""}`}
              onClick={() => changeTag("popular")}
            >
              Popular
            </div>
            <div
              className={`tag__item ${
                activeTag === "favorite" ? "active" : ""
              }`}
              onClick={() => changeTag("favorite")}
            >
              Favorite
            </div>
            {tags.map((_tag, index) => (
              <div
                key={index}
                className={`tag__item ${activeTag === _tag ? "active" : ""}`}
                onClick={() => changeTag(_tag)}
              >
                {_tag}
              </div>
            ))}
          </div>
        </div>
      </Container> */}
    </>
  );
}

export default DiscoverMleiha;
