import { Button } from "reactstrap";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { favoriteDetail } from "../store/actions/authActions";
import PackageDetail from "../components/PackageDetail";
import {
  ArrowLeft,
  FavoriteIcon,
} from "../components/PackageDetail/SvgComponentss";
import { useDispatch, useSelector } from "react-redux";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

function ProductDetails() {
  const history = useHistory();
  const { packages } = useSelector((state) => state.discover);
  const packageName = useSelector((state) => state.discover);
  const tabs = [
    { id: 1, name: "Description" },
    { id: 2, name: "Terms" },
    { id: 3, name: "Gallery" },
  ];
  const [megaSliderImages, setMegaSliderImages] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [activeTab, setActiveTab] = useState(1);
  const { token } = useSelector(({ authUser }) => ({
    token: authUser.uid,
  }));
  const dispatch = useDispatch();

  return (
    <>
      <Container className="mt-4 mb-5">
        <Button
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            history.push("/b2bagents");
          }}
        >
          <ArrowLeft />
        </Button>
        <span style={{ marginLeft: "2%", fontSize: 24 }}>
          {packageName.package?.package_name}
        </span>

        <Row>
          <Col lg="12" className="mt-3 mb-3">
            <PackageDetail />
          </Col>
        </Row>
        <div className="package-content package-detail-info">
          <div className="tab-package-btn">
            <div
              className=""
              style={{
                overflowX: "auto",
                display: "flex",
              }}
            >
              {tabs.map(({ id, name }) => (
                <button
                  key={id}
                  // className={cx("btn", { active: id === activeTab })}
                  onClick={() => setActiveTab(id)}
                >
                  {name}
                </button>
              ))}
            </div>
          </div>
          <div className="content">
            {activeTab === 1 && (
              <div
                dangerouslySetInnerHTML={{
                  __html: packageName.package?.description,
                }}
              ></div>
            )}
            {activeTab === 2 && (
              <div
                dangerouslySetInnerHTML={{
                  __html: packageName.package?.term_conditions,
                }}
              ></div>
            )}
            {activeTab === 3 && (
              <div>
                <div className="row">
                  {packageName.package &&
                    JSON.parse(packageName.package?.mega_slider_images).map(
                      (value, index) => (
                        <div
                          className="col-sm-4"
                          key={index}
                          onClick={() => {
                            setOpen(true);
                            setPhotoIndex(index);
                          }}
                        >
                          <img
                            src={`http://api.discovershurooq.ae/files/${value}`}
                          ></img>
                        </div>
                      )
                    )}
                </div>
                {isOpen &&
                  packageName.package &&
                  JSON.parse(packageName.package?.mega_slider_images).length >
                    0 && (
                    <Lightbox
                      mainSrc={
                        `http://api.discovershurooq.ae/files/` +
                        JSON.parse(packageName.package?.mega_slider_images)[
                          photoIndex
                        ]
                      }
                      nextSrc={
                        `http://api.discovershurooq.ae/files/` +
                        JSON.parse(packageName.package?.mega_slider_images)[
                          (photoIndex + 1) %
                            JSON.parse(packageName.package?.mega_slider_images)
                              .length
                        ]
                      }
                      prevSrc={
                        `http://api.discovershurooq.ae/files/` +
                        JSON.parse(packageName.package?.mega_slider_images)[
                          (photoIndex +
                            JSON.parse(packageName.package?.mega_slider_images)
                              .length -
                            1) %
                            JSON.parse(packageName.package?.mega_slider_images)
                              .length
                        ]
                      }
                      onCloseRequest={() => setOpen(false)}
                      onMovePrevRequest={() =>
                        setPhotoIndex(
                          (photoIndex +
                            JSON.parse(packageName.package?.mega_slider_images)
                              .length -
                            1) %
                            JSON.parse(packageName.package?.mega_slider_images)
                              .length
                        )
                      }
                      onMoveNextRequest={() =>
                        setPhotoIndex(
                          (photoIndex + 1) %
                            JSON.parse(packageName.package?.mega_slider_images)
                              .length
                        )
                      }
                    />
                  )}
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  );
}

export default ProductDetails;
