import React from "react";
import { AwesomeButton } from "react-awesome-button";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { Modal, ModalBody } from "reactstrap";

import "./styles.css";

const ClearCartModal = ({ isOpen, toggleOpen }) => {
	const [, setCookies] = useCookies("cart");

	const onClear = () => {
		setCookies(
			"cart",
			{
				packages: [],
				coupon: null,
				coupon_id: "",
			},
			{
				path: "/",
			}
		);
		toast.info("Cart cleared!", {
			position: "top-center",
		});
		toggleOpen();
	};

	const onCancel = () => {
		toggleOpen();
	};

	return (
		<Modal centered isOpen={isOpen} toggle={toggleOpen} size="md">
			<ModalBody className="px-5 py-4">
				<h4 className="mb-4">Are you sure you want to clear the cart?</h4>

				<div className="d-flex gap-2 cart__clear-buttons">
					<AwesomeButton onPress={onCancel} type="secondary" className="mt-3 w-50">
						No, cancel!
					</AwesomeButton>
					<AwesomeButton onPress={onClear} className="mt-3 danger w-50">
						Yes, clear the cart.
					</AwesomeButton>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default ClearCartModal;
