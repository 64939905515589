import { useMemo } from "react";
import { AwesomeButton } from "react-awesome-button";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { initiatePayment } from "../store/actions/discoverActions";

const PaymentUserData = ({ userData, status, purchaseResponse }) => {
  const token = useSelector((state) => state.authUser.uid);

  const dispatch = useDispatch();

  const getStatus = useMemo(() => {
    if (status === "1") return "success";
    if (status === "2") return "failed";
    return "pending";
  }, [status]);

  const onRetryPayment = () => {
    toast.info("Initiating Payment retry.");
    dispatch(
      initiatePayment(purchaseResponse, token, (paymentResponse) => {
        window.open(
          paymentResponse.payment_link,
          "Payment - Discover POS",
          `left=100,top=100,popup=yes`
        );
      })
    );
  };

  return (
    <div className="user__data">
      <div className="user__info">
        <div className="user__data--name">{userData.name}</div>
        <div className="user__data--email">
          <b>Email: </b>
          {userData.email}
        </div>
        <div className="user__data--mobile">
          <b>Mobile: </b>
          {userData.mobile}
        </div>
      </div>
      <div className="status">
        {getStatus === "failed" ? (
          <AwesomeButton onPress={onRetryPayment}>Retry Payment</AwesomeButton>
        ) : null}
        {getStatus === "pending" ? (
          <>
            <div className={`purchase__status ${getStatus}`}>
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>{" "}
              Waiting for payment
            </div>
          </>
        ) : null}
        {getStatus === "success" ? (
          <div className={`purchase__status ${getStatus}`}>{getStatus}</div>
        ) : null}
      </div>
    </div>
  );
};

export default PaymentUserData;
