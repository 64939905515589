import React, { useEffect, useState } from "react";

import { connect, useSelector } from "react-redux";
import { Button, Spinner, Alert } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { useHistory, withRouter } from "react-router-dom";
import { useCookies } from "react-cookie";

import Moment from "moment";
import Loader from "react-loader-spinner";
import SwipeableBottomSheet from "react-swipeable-bottom-sheet";
import ProductDetailSteps from "./productDetails/productDetailSteps";

import {
  couponValidity,
  getDateAvailable,
  getPackagesDetails,
  getPackagesSlots,
} from "../../store/actions/discoverActions";
import moment from "moment";

import "react-toastify/dist/ReactToastify.css";
import "./styles/main.css";
import { Modal, ModalBody } from "reactstrap";

const PackageOptions = (props) => {
  let isMeal = false,
    isSub = false,
    isTime = false,
    isDate = false,
    isTransport = false;

  const {
    getPackageDetails,
    getDateAvailable,
    getPackageSlots,
    couponValidity,
  } = props;

  const history = useHistory();

  const {
    token,
    packageDetails,
    dateAvailability,
    packageSlots,
    couponValidityStatus,
    couponValidityData,
  } = useSelector((state) => ({
    token: state.authUser.uid,
    packageDetails: state.discover.package,
    bookingData: state.discover.bookingData,
    packageSlots: state.discover.packageSlots,
    dateAvailability: state.discover.dateAvailability,
    couponValidityData: state.discover.couponValidityData,
    bookingPackageStatus: state.discover.bookingPackageStatus,
    couponValidityStatus: state.discover.couponValidityStatus,
  }));

  const [addPerson, setAddPerson] = useState(0);
  const [addChild, setAddChild] = useState(0);
  const package_slug = props.package_slug || props.match.params.package_slug;
  const [showTimeSlot, setShowTimeSlot] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [realDate, setRealDate] = useState("");
  const [adultPrice, setAdultPrice] = useState("");
  const [childPrice, setChildPrice] = useState("");
  const [subPackages, setSubPackages] = useState([]);
  const [meals, setMeals] = useState([]);
  const [transports, setTransports] = useState([]);
  const [isOneWay, setOneWay] = useState(true);
  const [transport_id, setTransportID] = useState(0);
  const [transport_name, setTransportName] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(-1);
  const [timeSlotValue, setTimeSlotValue] = useState("");
  const [time_slot, setTimeSlot] = useState("");
  const [btnPackageName, setPackageName] = useState("SELECT PACKAGE");
  const [btnMealName, setMealName] = useState("SELECT MEALS");
  const [packagePrice, setPackagePrice] = useState(0);
  const [subpackages_adult_qty, setSubAdultQty] = useState(0);
  const [subpackages_child_qty, setSubChildQty] = useState(0);
  const [mealPrice, setMealPrice] = useState(0);
  const [transportPrice, setTransportPrice] = useState(0);
  const [isBooking, showBooking] = useState(false);
  const [isDateSelector, showDateSelector] = useState(false);
  const [isTimeSlotSelector, showTimeSlotSelector] = useState(false);
  const [isSubPackageSelector, showSubPackageSelector] = useState(false);
  const [isMealSelector, showMealSelector] = useState(false);
  const [isTransportSelector, showTransportSelector] = useState(false);
  const [progressCount, setProgressCount] = useState(1);
  const [progressValue, setProgressValue] = useState(100);
  const [isLoading, setLoading] = useState(false);
  const [total_adult_tickets, setTotalAdultTickets] = useState(0);
  const [total_child_tickets, setTotalChildTickets] = useState(0);
  const [isAlert, setAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [showDetails, isShowDetails] = useState(false);
  const [wobble, setWobble] = useState(0);
  const [showCoupon, isShowCoupon] = useState(false);
  const [couponValue, setCouponValue] = useState("");
  const [coupon_id, setCouponID] = useState("");
  const [enable_date_picker, setEnableDatePicker] = useState(false);
  const [enable_time_picker, setEnableTimePicker] = useState(false);
  const [enable_meal, setEnableMeal] = useState(false);
  const [enable_sub_packages, setEnableSubPackages] = useState(false);
  const [enable_transport, setEnableTransport] = useState(false);
  const [offValue, setOffValue] = useState(0);
  const [total, setTotal] = useState(0);
  const [offType, setOffType] = useState("");
  const [couponBuy, setCouponBuy] = useState("");
  const [couponBuyQty, setCouponBuyQty] = useState(0);
  const [couponGet, setCouponGet] = useState("");
  const [couponGetQty, setCouponGetQty] = useState(0);
  const [isCoupon, setCoupon] = useState(false);
  const [realCoupon, setRealCoupon] = useState("");
  const [disabled_dates, setDisabledDates] = useState([]);
  const [disabled_weeks, setDisabledWeeks] = useState([]);
  const [isSubTitle, showSubTitle] = useState([]);
  const [isMealTitle, showMealTitle] = useState([]);
  const [adult_overall_limit, setAdultOverAllLimit] = useState("");
  const [child_overall_limit, setChildOverAllLimit] = useState("");
  const [adult_booked_times, setAdultBookedTimes] = useState("");
  const [child_booked_times, setChildBookedTimes] = useState("");
  const [isLoad, setLoad] = useState(0);
  const [cookies, setCookies] = useCookies(["ref", "cart"]);

  let date = new Date();
  let minDate = new Date(packageDetails?.start_date);
  date = new Date();
  date.setMonth(date.getMonth() + 3);
  let maxDate = new Date(packageDetails?.end_date);

  useEffect(() => {
    setAddPerson(0);
    setAddChild(0);
    setShowTimeSlot(false);
    setSelectedDate("");
    setRealDate("");
    setAdultPrice("");
    setChildPrice("");
    setSubPackages([]);
    setMeals([]);
    setTransports([]);
    setOneWay(true);
    setTransportID(0);
    setTransportName("");
    setTimeSlots([]);
    setSelectedTimeSlot(-1);
    setTimeSlotValue("");
    setTimeSlot("");
    setPackagePrice(0);
    setMealPrice(0);
    setTransportPrice(0);
    showBooking(false);
    showDateSelector(false);
    showTimeSlotSelector(false);
    showSubPackageSelector(false);
    showMealSelector(false);
    showTransportSelector(false);
    setProgressCount(1);
    setProgressValue(100);
    setTotalAdultTickets(0);
    setTotalChildTickets(0);
    setAlert(false);
    setAlertText("");
    isShowDetails(false);
    setWobble(0);
    setEnableDatePicker(false);
    setEnableTimePicker(false);
    setEnableMeal(false);
    setEnableSubPackages(false);
    setEnableTransport(false);
    setOffValue(0);
    setTotal(0);
    setOffType("");
    setCouponBuy("");
    setCouponBuyQty(0);
    setCouponGet("");
    setCouponGetQty(0);
    setRealCoupon("");
    setDisabledDates([]);
    setDisabledWeeks([]);
    showSubTitle([]);
    showMealTitle([]);
    setAdultOverAllLimit("");
    setChildOverAllLimit("");
    setAdultBookedTimes("");
    setChildBookedTimes("");
    const data = new FormData();
    data.append("action", "b2bpackagesDetails");
    data.append("slug", package_slug);

    getPackageDetails(data, token);

    setLoad(1);

    window.scroll({
      top: 0,
    });
    // eslint-disable-next-line
  }, [package_slug]);

  useEffect(() => {
    if (isLoad) {
      if (packageDetails?.redirect == 1) {
        window.location.href = packageDetails?.redirect_url;

        return;
      }
    }
    // eslint-disable-next-line
  }, [packageDetails]);
  const bookYourPackage = () => {
    setLoading(true);
    let subpackage_id = [];
    let subpackage_adult = [];
    let subpackage_child = [];
    let sub_package_label = [];
    for (let i = 0; i < subPackages?.length; i++) {
      if (
        subPackages[i].adult_person == 0 &&
        subPackages[i].child_person == 0
      ) {
        continue;
      }
      subpackage_id.push(subPackages[i].id);
      subpackage_adult.push(subPackages[i].adult_person);
      subpackage_child.push(subPackages[i].child_person);
      sub_package_label.push(subPackages[i].label);
    }
    let meal_id = [];
    let meal_qty = [];
    let meal_label = [];
    for (let i = 0; i < meals?.length; i++) {
      if (meals[i].person == 0) {
        continue;
      }
      meal_id.push(meals[i].id);
      meal_qty.push(meals[i].person);
      meal_label.push(meals[i].label);
    }

    let bookData = {
      package_id: packageDetails?.id,
      adult_qty: addPerson,
      child_qty: addChild,
    };
    if (subpackage_id.length > 0) {
      bookData = {
        ...bookData,
        subpackages_id: subpackage_id.map((id) => Number(id)),
        subpackages_adult_qty: subpackage_adult,
        subpackages_child_qty: subpackage_child,
        sub_package_label: sub_package_label,
      };
    }
    if (timeSlots) {
      bookData = {
        ...bookData,
        time_slot_id: timeSlots[selectedTimeSlot]?.id,
        time_slot_data: timeSlots[selectedTimeSlot],
      };
    }
    // alert(Moment(selectedDate).format("YYYY-MM-DD"));
    if (packageDetails?.enable_date_picker) {
      bookData = {
        ...bookData,
        date: Moment(selectedDate).format("YYYY-MM-DD"),
      };
    }
    if (meal_id.length > 0) {
      bookData = { ...bookData, meals_id: meal_id.map((id) => Number(id)) };
    }
    if (meal_qty.length > 0) {
      bookData = { ...bookData, meals_qty: meal_qty };
    }
    if (meal_label.length > 0) {
      bookData = { ...bookData, meal_label: meal_label };
    }
    if (transports.length > 0) {
      if (transport_id != 0) {
        bookData = {
          ...bookData,
          transport_id: transport_id,
          transport_type: isOneWay,
          transport_name: transport_name,
        };
      }
    }
    if (coupon_id?.length) {
      bookData = { ...bookData, coupon_id: coupon_id };
    }
    if (cookies.ref?.length) bookData = { ...bookData, referral: cookies.ref };
    bookData.total = total;
    bookData.package_name = packageDetails.package_name;
    toast.info("Added to cart.", { position: "top-center" });
    setCookies(
      "cart",
      {
        packages: [...(cookies.cart?.packages || []), bookData],
        coupon: cookies.cart?.coupon || null,
      },
      {
        path: "/",
      }
    );
    history.push("/");
    setLoading(false);
  };
  useEffect(() => {
    let flag = false;
    for (var i = 0; i < subPackages.length; i++) {
      if (subPackages[i].adult_person > 0 || subPackages[i].child_person > 0) {
        flag = true;
        showSubTitle(true);
      }
    }
    if (flag == false) {
      showSubTitle(false);
    }
  }, [subPackages]);
  useEffect(() => {
    let flag = false;
    for (var i = 0; i < meals.length; i++) {
      if (meals[i].person > 0) {
        flag = true;
        showMealTitle(true);
      }
    }
    if (flag == false) {
      showMealTitle(false);
    }
  }, [meals]);
  useEffect(() => {
    if (packageDetails?.price) {
      setAdultPrice(packageDetails?.price);
    }
    if (packageDetails?.child_price) {
      setChildPrice(packageDetails?.child_price);
    }
    if (packageDetails?.adult_default_qty) {
      setAddPerson(Number(packageDetails?.adult_default_qty));
    }
    if (packageDetails?.child_default_qty) {
      setAddChild(Number(packageDetails?.child_default_qty));
    }
    setTotal(packageDetails?.price);
    if (
      packageDetails?.enable_date_picker != "1" &&
      packageDetails?.enable_meal != "1" &&
      packageDetails?.enable_sub_packages != "1" &&
      packageDetails?.enable_time_picker != "1" &&
      packageDetails?.enable_transport != "1"
    ) {
      showBooking(true);
    }
    let disabled_dates_data = [];

    if (packageDetails?.event_dates?.length) {
      const event_date = JSON.parse(packageDetails?.event_dates);
      var currDate = new Date();
      var newDate = new Date(
        currDate.getFullYear() + 1,
        currDate.getMonth(),
        currDate.getDate()
      );
      const yearData = [...event_date];
      for (var d = new Date(); d <= newDate; d.setDate(d.getDate() + 1)) {
        yearData.push(moment(d).format("YYYY-MM-DD"));
      }
      const result = yearData.filter(function (e) {
        return yearData.indexOf(e) == yearData.lastIndexOf(e);
      });
      disabled_dates_data.push(result);
    }

    if (packageDetails?.disabled_dates?.length) {
      disabled_dates_data.push(...packageDetails?.disabled_dates);
    }

    // if (packageDetails?.disable_booking_prior == "1") {
    //   const curDate = new Date();

    //   if (curDate.getHours() >= 1) {
    //     let day = curDate.getDate() + 1;
    //     let date =
    //       curDate.getMonth() + 1 + "/" + day + "/" + curDate.getFullYear();
    //     let date1 =
    //       curDate.getMonth() +
    //       1 +
    //       "/" +
    //       curDate.getDate() +
    //       "/" +
    //       curDate.getFullYear();
    //     disabled_dates_data.push(date);
    //     disabled_dates_data.push(date1);
    //   } else {
    //     let date =
    //       curDate.getMonth() +
    //       1 +
    //       "/" +
    //       curDate.getDate() +
    //       "/" +
    //       curDate.getFullYear();
    //     disabled_dates_data.push(date);
    //   }
    // }

    if (packageDetails?.disable_booking_prior) {
      var curDate = new Date();
      let currentDate =
        curDate.getMonth() +
        1 +
        "/" +
        curDate.getDate() +
        "/" +
        curDate.getFullYear();
      disabled_dates_data.push(currentDate);
      curDate = new Date(
        curDate.getTime() +
          Number(packageDetails?.disable_booking_prior) * 60 * 60 * 1000
      );
      let day = curDate.getDate();
      let date =
        curDate.getMonth() + 1 + "/" + day + "/" + curDate.getFullYear();
      disabled_dates_data.push(date);
    }

    setDisabledDates(disabled_dates_data);

    if (packageDetails?.disabled_weeks) {
      let weeks = (packageDetails?.disabled_weeks).split(",");
      for (var i = 0; i < weeks.length; i++) {
        if (weeks[i] == "monday") {
          setDisabledWeeks((weeks) => [...weeks, 1]);
        } else if (weeks[i] == "tuesday") {
          setDisabledWeeks((weeks) => [...weeks, 2]);
        } else if (weeks[i] == "wednesday") {
          setDisabledWeeks((weeks) => [...weeks, 3]);
        } else if (weeks[i] == "thursday") {
          setDisabledWeeks((weeks) => [...weeks, 4]);
        } else if (weeks[i] == "friday") {
          setDisabledWeeks((weeks) => [...weeks, 5]);
        } else if (weeks[i] == "saturday") {
          setDisabledWeeks((weeks) => [...weeks, 6]);
        } else if (weeks[i] == "sunday") {
          setDisabledWeeks((weeks) => [...weeks, 0]);
        }
      }
    }
    if (packageDetails?.id) {
      const dateCheck = new FormData();
      dateCheck.append("action", "dateAvailabilityCheck");
      dateCheck.append("id", packageDetails?.id);
      dateCheck.append("date", Moment(new Date()).format("MM/DD/YYYY"));
      getDateAvailable(dateCheck);
      const slotsData = new FormData();
      slotsData.append("action", "packageSlots");
      slotsData.append("id", packageDetails?.id);
      // slotsData.append("date", Moment(new Date()).format("MM/DD/YYYY"));
      getPackageSlots(slotsData);
    }
    let count = 6;
    let flag = false;
    if (packageDetails?.enable_date_picker == "1") {
      flag = true;
    }
    if (!flag) {
      setEnableDatePicker(false);
      count--;
    } else {
      setEnableDatePicker(true);
      flag = false;
    }

    setSubPackages([]);
    if (packageDetails?.enable_sub_packages == "1") {
      for (let i = 0; i < packageDetails?.sub_packages.length; i++) {
        if (
          packageDetails?.sub_packages[i].enable == "1" &&
          packageDetails?.sub_packages[i].package_name &&
          packageDetails?.sub_packages[i].package_name.length > 2 &&
          packageDetails?.sub_packages[i].adult_price
        ) {
          flag = true;
          setSubPackages((subPackages) => [
            ...subPackages,
            {
              value: `${packageDetails?.sub_packages[i]?.package_name}`,
              label: `${packageDetails?.sub_packages[i]?.package_name}`,
              adult_price: `${packageDetails?.sub_packages[i]?.adult_price}`,
              child_price: `${packageDetails?.sub_packages[i]?.child_price}`,
              adult_person: 0,
              id: `${packageDetails?.sub_packages[i]?.id}`,
              child_person: 0,
            },
          ]);
        }
      }
    }
    if (!flag) {
      setEnableSubPackages(false);
      count--;
    } else {
      setEnableSubPackages(true);
      flag = false;
    }
    setMeals([]);
    if (packageDetails?.enable_meal == "1") {
      for (let i = 0; i < packageDetails?.meals.length; i++) {
        if (
          packageDetails?.meals[i].enable == "1" &&
          packageDetails?.meals[i].meal_name &&
          packageDetails?.meals[i].meal_name.length > 1 &&
          packageDetails?.meals[i].price
        ) {
          flag = true;
          setMeals((meals) => [
            ...meals,
            {
              value: `${packageDetails?.meals[i]?.meal_name}`,
              label: `${packageDetails?.meals[i]?.meal_name}`,
              price: `${packageDetails?.meals[i]?.price}`,
              person: 0,
              description: `${packageDetails?.meals[i]?.description}`,
              id: `${packageDetails?.meals[i]?.id}`,
            },
          ]);
        }
      }
    }
    if (!flag) {
      setEnableMeal(false);
      count--;
    } else {
      setEnableMeal(true);
      flag = false;
    }
    setTransports([]);
    if (packageDetails?.enable_transport == "1") {
      for (let i = 0; i < packageDetails?.transport.length; i++) {
        if (
          packageDetails?.transport[i].enable == "1" &&
          packageDetails?.transport[i].name.length > 1 &&
          packageDetails?.transport[i].one_way.length > 0
        ) {
          flag = true;
          setTransports((transport) => [
            ...transport,
            {
              value: `${packageDetails?.transport[i]?.name}`,
              label: `${packageDetails?.transport[i]?.name}`,
              one_way: `${packageDetails?.transport[i]?.one_way}`,
              two_way: `${packageDetails?.transport[i]?.two_way}`,
              id: `${packageDetails?.transport[i]?.id}`,
            },
          ]);
        }
      }
    }
    if (!flag) {
      setEnableTransport(false);
      count--;
    } else {
      setEnableTransport(true);
      flag = false;
    }
    if (packageDetails?.enable_time_picker == "1") {
      flag = true;
    }
    if (!flag) {
      setEnableTimePicker(false);
      count--;
    } else {
      setEnableTimePicker(true);
      flag = false;
    }
    setProgressCount(count);
    if (count > 1) {
      setProgressCount(count);
      setProgressValue(100 / count);
      showBooking(false);
    } else {
      setProgressValue(100);
    }
    if (timeSlots[0]?.value) {
      setTimeSlotValue(timeSlots[0].value.slice(0, 7));
    }
    if (packageDetails?.adult_booked_times) {
      setAdultBookedTimes(packageDetails?.adult_booked_times);
    }
    if (packageDetails?.adult_overall_limit) {
      setAdultOverAllLimit(packageDetails?.adult_overall_limit);
    }
    if (packageDetails?.child_booked_times) {
      setChildBookedTimes(packageDetails?.child_booked_times);
    }
    if (packageDetails?.child_overall_limit) {
      setChildOverAllLimit(packageDetails?.child_overall_limit);
    }
  }, [packageDetails]);
  useEffect(() => {
    let totalAmount =
      addPerson * packageDetails?.price +
      addChild * packageDetails?.child_price +
      packagePrice +
      mealPrice +
      // transportPrice * (addChild + addPerson);
      transportPrice;
    console.log(
      addPerson,
      addChild,
      packagePrice,
      mealPrice,
      transportPrice,
      totalAmount
    );
    if (offType == "aed") {
      if (totalAmount - offValue > 0) {
        setTotal(totalAmount - offValue);
      } else {
        setTotal(0);
      }
    } else if (offType == "percentage") {
      if (totalAmount - (totalAmount * offValue) / 100 > 0) {
        setTotal((totalAmount - (totalAmount * offValue) / 100).toFixed(2));
      } else {
        setTotal(0);
      }
    } else if (offType == "buy_x_get_y") {
      let buyQty = couponBuyQty;
      let freeQty = couponGetQty;

      let tempChildQty = addChild;
      let tempChildQtyt = addChild;
      let tempAdultQty = addPerson;
      let tempAdultQtyt = addPerson;

      let buyType = couponBuy;
      let freeType = couponGet;

      if (buyType == "adult") {
        for (var i = 1; i <= tempAdultQtyt; i++) {
          if (i % buyQty == 0) {
            if (freeType == "child") {
              for (var j = 1; j <= freeQty; j++) {
                if (tempChildQty > 0) tempChildQty = tempChildQty - 1;
              }
            } else if (freeType == "adult") {
              for (var j = 1; j <= freeQty; j++) {
                if (tempAdultQty > i) tempAdultQty = tempAdultQty - 1;
              }
            }
          }
        }
      } else if (buyType == "child") {
        for (var i = 1; i <= tempChildQtyt; i++) {
          if (i % buyQty == 0) {
            if (freeType == "child") {
              for (var j = 1; j <= freeQty; j++) {
                if (tempChildQty > i) tempChildQty = tempChildQty - 1;
              }
            } else if (freeType == "adult") {
              for (var j = 1; j <= freeQty; j++) {
                if (tempAdultQty > 0) tempAdultQty = tempAdultQty - 1;
              }
            }
          }
        }
      }
      var total_amount =
        tempChildQty * packageDetails?.child_price +
        tempAdultQty * packageDetails?.price;
      var couponVal =
        addPerson * adultPrice + addChild * childPrice - total_amount;
      setTotal(totalAmount - couponVal);
    } else {
      setTotal(totalAmount);
    }
  }, [addPerson, addChild, packagePrice, mealPrice, transportPrice]);
  useEffect(() => {
    setLoading(false);
    if (dateAvailability) {
      setTotalAdultTickets(dateAvailability?.adult_tickets);
      setTotalChildTickets(dateAvailability?.child_tickets);
    }
  }, [dateAvailability]);
  useEffect(() => {
    setTimeSlots([]);
    for (let i = 0; i < packageSlots?.length; i++) {
      if (packageSlots[i].adult_tickets === "") {
        var adultTicket = 9999;
      } else {
        var adultTicket = packageSlots[i].adult_tickets;
      }
      if (packageSlots[i].child_tickets === "") {
        var childTicket = 9999;
      } else {
        var childTicket = packageSlots[i].child_tickets;
      }
      console.log(adultTicket, childTicket);
      if (
        adultTicket >= addPerson &&
        childTicket >= addChild &&
        packageSlots[i].time_slot != "" &&
        packageSlots[i].enable === "1"
      ) {
        setTimeSlots((timeSlots) => [
          ...timeSlots,
          {
            value: `${packageSlots[i]?.time_slot}`,
            label: `${packageSlots[i]?.time_slot}`,
            adult_limit: `${
              packageSlots[i].adult_tickets == ""
                ? 1000
                : packageSlots[i].adult_tickets
            }`,
            child_limit: `${
              packageSlots[i]?.child_tickets == ""
                ? 1000
                : packageSlots[i]?.child_tickets
            }`,
            id: `${packageSlots[i]?.id}`,
          },
        ]);
      }
    }
  }, [packageSlots, addPerson, addChild]);
  const changeTimeSlot = (event) => {
    let index = parseInt(event.target.value);
    setTimeSlot(timeSlots[index].value.slice(0, 7));
    setSelectedTimeSlot(index);
    setTimeSlotValue(timeSlots[index].value.slice(0, 7));
  };
  function checkTransport(item, flag) {
    if (item == null && flag == false) {
      setTransportID(0);
      setTransportPrice(0);
      return;
    }
    setTransportName(item.value);
    setOneWay(flag);
    setTransportID(item.id);
    if (flag) {
      setTransportPrice(parseInt(item.one_way));
    } else {
      setTransportPrice(parseInt(item.two_way));
    }
  }
  const handleDateChange = (date) => {
    const d = new Date(date);
    console.log(date);
    // if (
    //   d.getDate() == "31" &&
    //   d.getMonth() == "11" &&
    //   packageDetails.destination_id == "1"
    // ) {
    //   alert(
    //     "Please call us on +971 6 802 1111 or email us on mleihamanagement@discovermleiha.ae to make your booking"
    //   );
    //   setSelectedDate(null);
    //   setRealDate("");
    //   return false;
    // }

    setLoading(true);
    setSelectedDate(date);
    setRealDate(Moment(date).format("DD/MM"));
    setTimeSlot("");
    setSelectedTimeSlot(-1);
    const dateCheck = new FormData();
    dateCheck.append("action", "dateAvailabilityCheck");
    dateCheck.append("id", packageDetails?.id);
    dateCheck.append("date", Moment(date).format("MM/DD/YYYY"));
    getDateAvailable(dateCheck);
    if (packageDetails?.enable_time_picker == "1") {
      const slotsData = new FormData();
      slotsData.append("action", "packageSlots");
      slotsData.append("id", packageDetails?.id);
      slotsData.append("date", Moment(date).format("MM/DD/YYYY"));
      getPackageSlots(slotsData);
    }
  };
  function addSubAdult(id, state, item) {
    if (state) {
      if (
        item.adult_person == 0
        // ||
        // item.adult_price == "" ||
        // parseInt(item.adult_price) == 0
      ) {
        return;
      }
      subPackages[id].adult_person = item.adult_person - 1;
    } else {
      // if (item.adult_price == "" || parseInt(item.adult_price) == 0) {
      //   return;
      // }
      subPackages[id].adult_person = item.adult_person + 1;
    }
    setSubPackages((subPackages) => [...subPackages]);
    calulatePackages();
  }

  function addSubChild(id, state, item) {
    if (state) {
      if (
        item.child_person == 0
        // ||
        // item.child_price == "" ||
        // parseInt(item.child_price) == 0
      ) {
        return;
      }
      subPackages[id].child_person = item.child_person - 1;
    } else {
      // if (item.child_price == "" || parseInt(item.child_price) == 0) {
      //   return;
      // }
      subPackages[id].child_person = item.child_person + 1;
    }
    setSubPackages((subPackages) => [...subPackages]);
    calulatePackages();
  }
  function addMealPerson(id, state, item) {
    if (state) {
      if (item.person == 0) {
        return;
      }
      meals[id].person = item.person - 1;
    } else {
      meals[id].person = item.person + 1;
    }
    setMeals((meals) => [...meals]);
    calulateMeals();
  }

  function calulatePackages() {
    let sum_adult = 0;
    let sum_child = 0;
    let sum_price = 0;
    for (let i = 0; i < subPackages?.length; i++) {
      sum_adult += parseInt(subPackages[i]?.adult_person);
      sum_price +=
        parseInt(subPackages[i]?.adult_price) *
        parseInt(subPackages[i]?.adult_person);
      sum_child += parseInt(subPackages[i]?.child_person);
      if (
        subPackages[i]?.child_price != "" &&
        subPackages[i]?.child_price != null
      ) {
        sum_price +=
          parseInt(subPackages[i]?.child_price) *
          parseInt(subPackages[i]?.child_person);
      }
    }
    setSubAdultQty(sum_adult);
    setSubChildQty(sum_child);
    setPackagePrice(sum_price);
    if (sum_adult + sum_child == 0) {
      setPackageName("SELECT PACKAGE");
    } else {
      setPackageName(sum_adult + sum_child + " items, " + sum_price + " AED");
    }
  }
  function calulateMeals() {
    let sum_person = 0;
    let sum_price = 0;
    for (let i = 0; i < meals?.length; i++) {
      sum_person += parseInt(meals[i]?.person);
      sum_price += parseInt(meals[i]?.price) * parseInt(meals[i]?.person);
    }
    setMealPrice(sum_price);
    if (sum_person == 0) {
      setMealName("SELECT MEALS");
    } else {
      setMealName(sum_person + " items, " + sum_price + " AED");
    }
  }

  const nextCheckPackage = () => {
    let temp = 100 / progressCount;
    if (!isDate) {
      isDate = true;
      if (parseInt(progressValue + temp) == 100) {
        showBooking(true);
      }
      if (!enable_date_picker) {
        showTimeSlotSelector(true);
        nextPage();
      }
    } else if (!isTime) {
      isTime = true;
      if (parseInt(progressValue + temp) == 100) {
        showBooking(true);
      }
      if (!enable_time_picker) {
        showSubPackageSelector(true);
        nextPage();
      }
    } else if (!isSub) {
      isSub = true;
      if (parseInt(progressValue + temp) == 100) {
        showBooking(true);
      }
      if (!enable_sub_packages) {
        showMealSelector(true);
        nextPage();
      }
    } else if (!isTransport) {
      isTransport = true;
      if (parseInt(progressValue + temp) == 100) {
        showBooking(true);
      }
      if (!enable_transport) {
        showTransportSelector(true);
        nextPage();
      }
    } else if (!isMeal) {
      isMeal = true;
      if (parseInt(progressValue + temp) == 100) {
        showBooking(true);
      }
      if (!enable_meal) {
        showBooking(true);
        nextPage();
      }
    }
  };
  function nextPage() {
    let err = "";
    if (packageDetails.adult_min_qty || packageDetails.child_min_qty) {
      if (addPerson < packageDetails.adult_min_qty) {
        err += `Select Adult - Minimum ${packageDetails.adult_min_qty}`;
      }
      if (
        packageDetails.adult_max_qty &&
        addPerson > packageDetails.adult_max_qty
      ) {
        err += `Select Adult - Maximum ${packageDetails.adult_max_qty}`;
      }
      if (addChild < packageDetails.child_min_qty) {
        err += `\nSelect Child - Minimum ${packageDetails.child_min_qty}`;
      }
      if (
        packageDetails.child_max_qty &&
        addChild > packageDetails.child_max_qty
      ) {
        err += `\nSelect Child - Maximum ${packageDetails.child_max_qty}`;
      }

      if (err != "") {
        alert(err);
        return false;
      }
    }

    if (packageDetails.overall_min_limit) {
      let total_person = addPerson + addChild;
      if (total_person < packageDetails.overall_min_limit) {
        alert(`Select Minimum ${packageDetails.overall_min_limit} Person`);
        return false;
      }
    }

    if (addPerson == 0 && addChild == 0) {
      alert("Select Passengers - Minimum 1");
      return false;
    }

    if (isDateSelector && enable_date_picker && !isTimeSlotSelector) {
      if (realDate == "") {
        alert("Please Select a Date");
        return false;
      }
      if (total_adult_tickets >= addPerson && total_child_tickets >= addChild) {
        let temp = 100 / progressCount;
        setProgressValue(progressValue + temp);
        if (isTransportSelector) {
          showMealSelector(true);
          nextCheckPackage();
        } else if (isSubPackageSelector) {
          showTransportSelector(true);
          nextCheckPackage();
        } else if (isTimeSlotSelector) {
          showSubPackageSelector(true);
          nextCheckPackage();
        } else if (isDateSelector) {
          showTimeSlotSelector(true);
          nextCheckPackage();
          // } else if (isAddressSelector) {
          //   showAddressSelector(true);
          //   nextCheckPackage();
        } else {
          showDateSelector(true);
          nextCheckPackage();
        }
        return true;
      } else {
        alert("Selected Date is Fully Booked");
        return false;
      }
    }
    if (isTimeSlotSelector && enable_time_picker && !isSubPackageSelector) {
      if (time_slot == "") {
        alert("Please Select a Time");
        return false;
      }
      if (timeSlots?.length < 0) {
        alert("Selected Time is Fully Booked");
        return false;
      }
    }
    if (isLoading) {
      return false;
    }
    let temp = 100 / progressCount;
    setProgressValue(progressValue + temp);
    if (isMealSelector) {
      showMealSelector(false);
      showSubPackageSelector(false);
      showTimeSlotSelector(false);
      showDateSelector(false);
      showTransportSelector(false);
    } else if (isTransportSelector) {
      showMealSelector(true);
      nextCheckPackage();
    } else if (isSubPackageSelector) {
      showTransportSelector(true);
      nextCheckPackage();
    } else if (isTimeSlotSelector) {
      showSubPackageSelector(true);
      nextCheckPackage();
    } else if (isDateSelector) {
      // showTimeSlotSelector(true);
      nextCheckPackage();
    } else {
      showDateSelector(true);
      nextCheckPackage();
    }
    return true;
  }

  function getProgressValue(slide_name) {
    let count = 2;
    switch (slide_name) {
      case "user":
        setProgressValue(100 / progressCount);
        if (100 / progressCount == 100) {
          showBooking(true);
        } else {
          showBooking(false);
        }
        break;
      case "date":
        setProgressValue((count * 100) / progressCount);
        if ((count * 100) / progressCount == 100) {
          showBooking(true);
        } else {
          showBooking(false);
        }
        break;
      case "time":
        if (enable_date_picker) {
          count++;
        }
        setProgressValue((count * 100) / progressCount);
        if ((count * 100) / progressCount == 100) {
          showBooking(true);
        } else {
          showBooking(false);
        }
        break;
      case "sub_packages":
        if (enable_date_picker) {
          count++;
        }
        if (enable_time_picker) {
          count++;
        }
        setProgressValue((count * 100) / progressCount);
        if ((count * 100) / progressCount == 100) {
          showBooking(true);
        } else {
          showBooking(false);
        }
        break;
      case "transports":
        if (enable_date_picker) {
          count++;
        }
        if (enable_time_picker) {
          count++;
        }
        if (enable_sub_packages) {
          count++;
        }
        setProgressValue((count * 100) / progressCount);
        if ((count * 100) / progressCount == 100) {
          showBooking(true);
        } else {
          showBooking(false);
        }
        break;
      case "meals":
        if (enable_sub_packages) {
          count++;
        }
        if (enable_time_picker) {
          count++;
        }
        if (enable_date_picker) {
          count++;
        }
        if (enable_transport) {
          count++;
        }
        setProgressValue((count * 100) / progressCount);
        if ((count * 100) / progressCount == 100) {
          showBooking(true);
        } else {
          showBooking(false);
        }
        break;
      default:
        break;
    }
  }
  function gotoSlide(slide_name) {
    if (addPerson == 0 && addChild == 0) {
      alert("Select Passengers - Minimum 1");
      return false;
    }
    switch (slide_name) {
      case "user":
        isDate = false;
        isTime = false;
        isTransport = false;
        isSub = false;
        isMeal = false;
        getProgressValue(slide_name);
        showMealSelector(false);
        showSubPackageSelector(false);
        showTimeSlotSelector(false);
        showDateSelector(false);
        showTransportSelector(false);
        return true;
      case "date":
        isDate = true;
        isTime = false;
        isTransport = false;
        isSub = false;
        isMeal = false;
        getProgressValue(slide_name);
        showMealSelector(false);
        showSubPackageSelector(false);
        showTimeSlotSelector(false);
        showTransportSelector(false);
        showDateSelector(true);
        return true;
      case "time":
        if (realDate == "" && enable_date_picker) {
          alert("Please Select a Date");
          return false;
        }
        if (total_adult_tickets < addPerson || total_child_tickets < addChild) {
          alert("Selected Date is Fully Booked");
          return false;
        }
        isDate = true;
        isTime = true;
        isTransport = false;
        isSub = false;
        isMeal = false;
        getProgressValue(slide_name);
        showMealSelector(false);
        showSubPackageSelector(false);
        showTimeSlotSelector(true);
        showTransportSelector(false);
        showDateSelector(true);
        return true;
      case "sub_packages":
        if (realDate == "" && enable_date_picker) {
          alert("Please Select a Date");
          return false;
        }
        if (total_adult_tickets < addPerson || total_child_tickets < addChild) {
          alert("Selected Time is Fully Booked");
          return false;
        }
        if (time_slot == "" && enable_time_picker) {
          return false;
        }
        isDate = true;
        isTime = true;
        isSub = true;
        isTransport = false;
        isMeal = false;
        getProgressValue(slide_name);
        showTransportSelector(false);
        showMealSelector(false);
        showSubPackageSelector(true);
        showTimeSlotSelector(true);
        showDateSelector(true);
        return true;
      case "transports":
        if (realDate == "" && enable_date_picker) {
          alert("Please Select a Date");
          return false;
        }
        if (total_adult_tickets < addPerson || total_child_tickets < addChild) {
          alert("Selected Date is Fully Booked");
          return false;
        }
        if (time_slot == "" && enable_time_picker) {
          return false;
        }
        isDate = true;
        isTime = true;
        isSub = true;
        isTransport = true;
        isMeal = false;
        getProgressValue(slide_name);
        showMealSelector(false);
        showSubPackageSelector(true);
        showTimeSlotSelector(true);
        showTransportSelector(true);
        showDateSelector(true);
        return true;
      case "meals":
        if (realDate == "" && enable_date_picker) {
          alert("Please Select a Date");
          return false;
        }
        if (total_adult_tickets < addPerson || total_child_tickets < addChild) {
          alert("Selected Date is Fully Booked");
          return false;
        }
        if (time_slot == "" && enable_time_picker) {
          return false;
        }
        isDate = true;
        isTime = true;
        isSub = true;
        isTransport = true;
        isMeal = true;
        getProgressValue(slide_name);
        showMealSelector(true);
        showSubPackageSelector(true);
        showTimeSlotSelector(true);
        showTransportSelector(true);
        showDateSelector(true);
        return true;
      default:
        break;
    }
  }

  const setNumPerson = (adult, child) => {
    setAddPerson(adult);
    setAddChild(child);
  };
  const coupon = () => {
    const data = new FormData();
    data.append("action", "couponValidity");
    data.append("package", packageDetails?.id);
    data.append("coupon", couponValue);
    couponValidity(data);
  };
  useEffect(() => {
    let totalAmount =
      addPerson * packageDetails?.price +
      addChild * packageDetails?.child_price +
      packagePrice +
      mealPrice +
      transportPrice;
    if (couponValidityStatus == 0) {
      setCoupon(false);
      setCouponID("");
      setCouponValue("");
      setRealCoupon("");
      setOffType("");
      setCouponBuy("");
      setCouponBuyQty(0);
      setCouponGet("");
      setCouponGetQty(0);
      setOffValue(0);
      setAlert(true);
      setAlertText("Coupon not Valid or Expired");
      setTotal(totalAmount);
      setTimeout(
        function () {
          setAlert(false);
        }.bind(this),
        2000
      );
    }
    if (
      couponValidityData &&
      couponValidityData.packages &&
      couponValidityData.packages.length > 0 &&
      !couponValidityData?.packages
        .split(",")
        .includes(packageDetails?.id?.toString())
    ) {
      setCoupon(false);
      setCouponID("");
      setCouponValue("");
      setRealCoupon("");
      setOffType("");
      setCouponBuy("");
      setCouponBuyQty(0);
      setCouponGet("");
      setCouponGetQty(0);
      setOffValue(0);
      setAlert(true);
      setAlertText("Coupon not valid for this Package");
      setTotal(totalAmount);
      setTimeout(
        function () {
          setAlert(false);
        }.bind(this),
        2000
      );
    }
    // check if total => min_cart from coupon
    if (
      couponValidityData &&
      couponValidityData.min_cart &&
      total < couponValidityData.min_cart
    ) {
      setCoupon(false);
      setCouponID("");
      setCouponValue("");
      setRealCoupon("");
      setOffType("");
      setCouponBuy("");
      setCouponBuyQty(0);
      setCouponGet("");
      setCouponGetQty(0);
      setOffValue(0);
      setAlert(true);
      setTotal(totalAmount);
      const amount = parseInt(couponValidityData.min_cart) - total;
      setAlertText(`Add ${amount} Amount more to apply this coupon.`);
      setTimeout(
        function () {
          setAlert(false);
        }.bind(this),
        2000
      );
    } else if (couponValidityStatus == 1) {
      setCoupon(true);
      setCouponID(couponValidityData?.id);
      setOffType(couponValidityData?.type);
      setCouponBuy(couponValidityData?.buy);
      setCouponBuyQty(couponValidityData?.buy_qty);
      setCouponGet(couponValidityData?.free);
      setCouponGetQty(couponValidityData?.free_qty);
      // set Off Value to min properties off/max_discount
      setOffValue(parseInt(couponValidityData?.off));
      setRealCoupon(couponValue);
      if (couponValidityData?.type == "aed") {
        console.log(couponValidityData?.off);
        console.log(couponValidityData?.max_discount);

        let maxDiscount = couponValidityData?.off;

        if (couponValidityData?.max_discount) {
          maxDiscount = Math.min(
            parseInt(couponValidityData?.off),
            parseInt(couponValidityData?.max_discount)
          );
        }

        if (totalAmount - maxDiscount > 0) {
          setTotal(totalAmount - maxDiscount);
        } else {
          setTotal(0);
        }
      } else if (couponValidityData?.type == "percentage") {
        if (totalAmount - (totalAmount * couponValidityData?.off) / 100 > 0) {
          let maxDiscount = (
            (totalAmount * couponValidityData?.off) /
            100
          ).toFixed(2);

          if (couponValidityData?.max_discount) {
            maxDiscount = Math.min(
              parseInt(maxDiscount),
              parseInt(couponValidityData?.max_discount)
            );
          }

          setTotal(totalAmount - maxDiscount);
        } else {
          setTotal(0);
        }
      } else if (couponValidityData?.type == "buy_x_get_y") {
        let buyQty = couponValidityData?.buy_qty;
        let freeQty = couponValidityData?.free_qty;

        let tempChildQty = addChild;
        let tempChildQtyt = addChild;
        let tempAdultQty = addPerson;
        let tempAdultQtyt = addPerson;

        let buyType = couponValidityData?.buy;
        let freeType = couponValidityData?.free;

        if (buyType == "adult") {
          for (var i = 1; i <= tempAdultQtyt; i++) {
            if (i % buyQty == 0) {
              if (freeType == "child") {
                for (var j = 1; j <= freeQty; j++) {
                  if (tempChildQty > 0) tempChildQty = tempChildQty - 1;
                }
              } else if (freeType == "adult") {
                for (var j = 1; j <= freeQty; j++) {
                  if (tempAdultQty > i) tempAdultQty = tempAdultQty - 1;
                }
              }
            }
          }
        } else if (buyType == "child") {
          for (var i = 1; i <= tempChildQtyt; i++) {
            if (i % buyQty == 0) {
              if (freeType == "child") {
                for (var j = 1; j <= freeQty; j++) {
                  if (tempChildQty > i) tempChildQty = tempChildQty - 1;
                }
              } else if (freeType == "adult") {
                for (var j = 1; j <= freeQty; j++) {
                  if (tempAdultQty > 0) tempAdultQty = tempAdultQty - 1;
                }
              }
            }
          }
        }
        var total_amount =
          tempChildQty * packageDetails?.child_price +
          tempAdultQty * packageDetails?.price;
        var couponVal =
          addPerson * adultPrice + addChild * childPrice - total_amount;
        setTotal(totalAmount - couponVal);
      } else {
        setTotal(totalAmount);
      }
      setWobble(1);
      toast("Coupon added successfully.", {
        hideProgressBar: true,
        position: "bottom-right",
        type: "success",
        pauseOnHover: true,
        style: {
          background: "#eb6139",
        },
      });
      setCouponValue("");
      couponValidity("");
    }
  }, [couponValidityStatus]);

  return (
    <>
      {packageDetails?.redirect == 1 ? (
        <div
          style={{
            textAlign: "center",
            position: "fixed",
            zIndex: "9999999999",
            left: "45%",
            top: "40%",
          }}
        >
          <Loader color="#FE5734" type="Oval" />
        </div>
      ) : (
        ""
      )}

      <div className="PackageOptionCard">
        {packageDetails?.id && (
          <ProductDetailSteps
            timeSlots={timeSlots}
            time_slot={time_slot}
            timeSlotValue={timeSlotValue}
            setShowTimeSlot={setShowTimeSlot}
            showTimeSlot={showTimeSlot}
            packageDetails={packageDetails}
            setNumPerson={setNumPerson}
            nextPage={nextPage}
            handleDateChange={handleDateChange}
            minDate={minDate}
            maxDate={maxDate}
            selectedDate={selectedDate}
            isBooking={isBooking}
            bookYourPackage={bookYourPackage}
            gotoSlide={gotoSlide}
            ticketsValue={addPerson * adultPrice + addChild * childPrice}
            realDate={realDate}
            packagePrice={packagePrice}
            mealPrice={mealPrice}
            addPerson={addPerson}
            addChild={addChild}
            selectedTimeSlot={selectedTimeSlot}
            changeTimeSlot={changeTimeSlot}
            subPackages={subPackages}
            addSubAdult={addSubAdult}
            addSubChild={addSubChild}
            meals={meals}
            addMealPerson={addMealPerson}
            isLoading={isLoading}
            transports={transports}
            transportPrice={transportPrice}
            isShowDetails={isShowDetails}
            enable_date_picker={enable_date_picker}
            enable_time_picker={enable_time_picker}
            enable_transport={enable_transport}
            enable_meal={enable_meal}
            enable_sub_packages={enable_sub_packages}
            transport_id={transport_id}
            isOneWay={isOneWay}
            checkTransport={checkTransport}
            setTransportID={setTransportID}
            isShowCoupon={isShowCoupon}
            realCoupon={realCoupon}
            total={total}
            disabled_dates={disabled_dates}
            disabled_weeks={disabled_weeks}
          />
        )}
      </div>
      <Modal
        centered
        isOpen={showDetails}
        toggle={() => isShowDetails(false)}
        size="lg"
        // overflowHeight={0}
        // open={showDetails}
        // onChange={() => {
        //   isShowDetails(false);
        // }}
      >
        <ModalBody className="px-5 py-4">
          <div className="booking-data">
            <div
              className="action row"
              style={{ marginRight: 0, marginLeft: 0, textAlign: "center" }}
            >
              <div
                id="mdiv"
                style={{
                  marginLeft: "auto",
                  marginRight: "30px",
                  cursor: "pointer",
                }}
              >
                <div class="mdiv" onClick={() => isShowDetails(false)}>
                  <div class="md"></div>
                </div>
              </div>
              <div className="counter col-md-12 col-12 ">
                <h4 className="package_name">{packageDetails?.package_name}</h4>
                <h4 className="pending">Payment Pending</h4>
              </div>
              <div className="row counter total">
                <div className="col-md-12 col-12 coupon_title">
                  <p>AED {total == "" ? 0 : total}</p>
                </div>
                {realCoupon && (
                  <div className="col-md-12 col-12 coupon_content">
                    <p>
                      Discount Code: {realCoupon} [AED{" "}
                      {(
                        addPerson * adultPrice +
                        addChild * childPrice +
                        mealPrice +
                        packagePrice +
                        transportPrice -
                        total
                      ).toFixed(2)}
                      ]
                    </p>
                  </div>
                )}
              </div>
              {packageDetails?.enable_date_picker == "1" && (
                <div className="row counter">
                  <div className="col-md-6 col-6 align-left">
                    <p>
                      <b>Date</b>
                    </p>
                  </div>
                  <div className="col-md-6 col-6 align-right">
                    <p>
                      <b>{Moment(selectedDate).format("DD MMM, YYYY")}</b>
                    </p>
                    <p>{timeSlots[selectedTimeSlot]?.value}</p>
                  </div>
                  <hr></hr>
                </div>
              )}

              <div className="row counter">
                {(addPerson > 0 || addChild > 0) && (
                  <>
                    <div className="col-md-6 col-6 align-left package_title">
                      <p>
                        <b>Tickets:</b>
                      </p>
                    </div>
                    <div className="col-md-6 col-6 align-right package_title">
                      <p>
                        <b>
                          AED {addPerson * adultPrice + addChild * childPrice}
                        </b>
                      </p>
                    </div>
                    <div className="col-md-6 col-6 align-left">
                      <p>Adult: {addPerson}</p>
                    </div>
                    <div className="col-md-6 col-6 align-right">
                      <p>Child: {addChild}</p>
                    </div>
                    <hr></hr>
                  </>
                )}
              </div>
              <div className="row counter">
                {isSubTitle && (
                  <>
                    <div className="col-md-6 col-6 align-left package_title">
                      <p>
                        <b>SubPackages:</b>
                      </p>
                    </div>
                    <div className="col-md-6 col-6 align-right package_title">
                      <p>
                        <b>AED {packagePrice}</b>
                      </p>
                    </div>
                  </>
                )}
                {subPackages.map((item, id) => {
                  return (
                    <>
                      {(item.adult_person > 0 || item.child_person > 0) && (
                        <>
                          <div key={id} className="col-md-6 col-6 align-left">
                            <p>{item.value}</p>
                          </div>
                          <div
                            key={id + "p"}
                            className="col-md-6 col-6 align-right"
                          >
                            <p>
                              AED{" "}
                              {item.adult_price * item.adult_person +
                                item.child_person * item.child_price}
                            </p>
                          </div>
                          <div
                            key={id + "ad"}
                            className="col-md-6 col-6 align-left"
                          >
                            <p>Adult: {item.adult_person}</p>
                          </div>
                          <div
                            key={id + "ch"}
                            className="col-md-6 col-6 align-right"
                          >
                            <p>Child: {item.child_person}</p>
                          </div>
                          <hr></hr>
                        </>
                      )}
                    </>
                  );
                })}
              </div>
              <div className="row counter">
                {transport_id > 0 && (
                  <>
                    <div className="col-md-6 col-6 align-left package_title">
                      <p>
                        <b>People for Transport:</b>
                      </p>
                    </div>
                    <div className="col-md-6 col-6 align-right package_title">
                      <p>
                        <b>{addPerson + addChild}</b>
                      </p>
                    </div>
                    <div className="col-md-6 col-6 align-left">
                      <p>{transport_name}</p>
                    </div>
                    <div className="col-md-6 col-6 align-right">
                      <p>AED {transportPrice}</p>
                    </div>
                    <hr></hr>
                  </>
                )}
              </div>
              <div className="row counter">
                {isMealTitle && (
                  <>
                    <div className="col-md-6 col-6 align-left package_title">
                      <p>
                        <b>Meals:</b>
                      </p>
                    </div>
                    <div className="col-md-6 col-6 align-right package_title">
                      <p>
                        <b>AED {mealPrice}</b>
                      </p>
                    </div>
                  </>
                )}
                {meals.map((item, id) => {
                  return (
                    <>
                      {item.person > 0 && (
                        <>
                          <div key={id} className="col-md-6 col-6 align-left">
                            <p>{item.value}</p>
                          </div>
                          <div
                            key={id + "cpr"}
                            className="col-md-6 col-6 align-right"
                          >
                            <p>AED {item?.price * item.person}</p>
                          </div>
                          <hr></hr>
                        </>
                      )}
                    </>
                  );
                })}
              </div>
              <div className="col-md-6 col-6" style={{ marginTop: 2 }}>
                {isLoading ? (
                  <Button
                    className="next-btn"
                    color="success"
                    onClick={() => {
                      bookYourPackage();
                    }}
                  >
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Add to cart
                  </Button>
                ) : (
                  <>
                    {(adult_overall_limit == "" ||
                      parseInt(adult_overall_limit) >=
                        parseInt(adult_booked_times) + addPerson) &&
                    (child_overall_limit == "" ||
                      parseInt(child_overall_limit) >=
                        parseInt(child_booked_times) + addChild) ? (
                      <button
                        className="next-btn btn btn-primary"
                        onClick={() => {
                          bookYourPackage();
                        }}
                      >
                        Add to cart
                      </button>
                    ) : (
                      <button
                        className="next-btn btn btn-primary"
                        onClick={() => {}}
                      >
                        Package Slots are Fully Booked
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <SwipeableBottomSheet
        overflowHeight={0}
        open={showCoupon && !wobble}
        style={{
          marginBottom: 80,
          maxWidth: 450,
          width: "100%",
          margin: "auto",
        }}
        onChange={() => {
          isShowCoupon(false);
          setWobble(0);
          setCouponValue("");
        }}
      >
        <div
          className={isAlert ? "coupon-detail coupon-alert" : "coupon-detail"}
        >
          <div
            className="action row"
            style={{
              marginRight: 0,
              marginLeft: 0,
              textAlign: "center",
              backgroundColor: "#e4e4e4",
            }}
          >
            <div className="counter col-md-12 col-12">
              <div className="coupon row">
                <div className="col-md-12 col-12">
                  <input
                    placeholder="Promo Code"
                    value={couponValue}
                    onChange={(e) => setCouponValue(e.target.value)}
                  ></input>
                </div>
              </div>
              <div className="row" style={{ justifyContent: "center" }}>
                {isAlert && (
                  <Alert color="danger" style={{ marginBottom: 0 }}>
                    {alertText}
                  </Alert>
                )}
                {wobble ? (
                  <Alert color="success" style={{ marginBottom: 0 }}>
                    Coupon applied.
                  </Alert>
                ) : null}
              </div>
              {token ? (
                <div
                  className="apply row"
                  onClick={() => wobble !== 1 && coupon()}
                >
                  <p color="success">Apply</p>
                </div>
              ) : (
                <div
                  className="apply row"
                  wobble={wobble}
                  onAnimationEnd={() => {
                    setWobble(0);
                    isShowCoupon(false);
                  }}
                >
                  <p color="success">Signup to Apply Coupon</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </SwipeableBottomSheet>
      <ToastContainer />
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  getPackageDetails: getPackagesDetails,
  getDateAvailable: getDateAvailable,
  getPackageSlots: getPackagesSlots,
  couponValidity: couponValidity,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PackageOptions));
