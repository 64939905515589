import React, { useEffect, useState } from "react";

import { Form, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import { AwesomeButton } from "react-awesome-button";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

import "react-awesome-button/dist/themes/theme-blue.css";
import { couponValidity, fetchCouponsList, skipCoupon } from "../store/actions/discoverActions";

function ApplyCoupon(props) {
	let { isOpen, toggleOpen } = props;
	const dispatch = useDispatch();

	const { token, userDetails, couponValidityStatus, couponFetchStatus, couponList } = useSelector(({ authUser, discover }) => ({
		token: authUser.uid,
		userDetails: authUser.userDetails,
		couponValidityStatus: discover.couponValidityStatus,
		couponList: discover.couponList,
		couponFetchStatus: discover.couponFetchStatus,
	}));

	const [localCouponData, setLocalCouponData] = useState({ code: "", coupon: null });
	const [cookies, setCookie] = useCookies("cart");

	useEffect(() => {
		if (!couponList?.length) dispatch(fetchCouponsList(token));
		// eslint-disable-next-line
	}, []);

	const updateUserCouponData = (field, value) => {
		setLocalCouponData({ ...localCouponData, [field]: value });
	};

	const onCouponSubmit = () => {
		if (!localCouponData.code.length) return;

		const _coupon = couponList?.find((cpn) => cpn.name === localCouponData.code);

		if (!_coupon) {
			toast.error("Invalid coupon code.", {
				position: "top-center",
			});
			return;
		}

		const newCartData = {
			packages: cookies.cart?.packages || [],
			coupon_id: _coupon.id,
		};

		setCookie("cart", newCartData, { path: "/" });

		dispatch(
			couponValidity(newCartData, userDetails.id, token, () => {
				toast.success("Coupon added.", {
					position: "top-center",
				});
				toggleOpen();
			})
		);
	};

	const onSkip = () => {
		toggleOpen();
		dispatch(skipCoupon());
	};

	return (
		<>
			<Modal centered isOpen={isOpen} toggle={toggleOpen} size="lg" scrollable>
				<ModalHeader toggle={toggleOpen}>Apply Coupon</ModalHeader>
				<ModalBody>
					<Form
						onSubmit={(e) => {
							e.preventDefault();
						}}
					>
						<Label className="mt-2">Coupon Code</Label>
						<Input
							value={localCouponData.code}
							className="mb-2"
							onChange={(e) => {
								updateUserCouponData("code", e.target.value);
							}}
							readOnly
							placeholder="XXXXXXXXXX"
							type="text"
							required
						/>

						<div className="coupon__button-group">
							<AwesomeButton onPress={onSkip} type="secondary" className="mt-3">
								Skip
							</AwesomeButton>
							<AwesomeButton onPress={onCouponSubmit} type="primary" className="mt-3">
								{couponValidityStatus === "requested" || couponFetchStatus === "requested" ? (
									<div class="spinner-border text-light" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								) : null}
								Submit
							</AwesomeButton>
						</div>

						<p className="mt-3 ">
							<b>Available coupons codes</b>
						</p>
						<div className="mb-2 coupon__field">
							{couponList?.length
								? couponList.map((cpn, index) => (
										<div
											key={index}
											className={`coupon__item ${localCouponData.code === cpn.name ? "active" : ""}`}
											onClick={() => {
												updateUserCouponData("code", cpn.name);
											}}
										>
											<span>{cpn.name}</span>
										</div>
								  ))
								: null}
						</div>

						<div className="coupon__button-group">
							<AwesomeButton onPress={onSkip} type="secondary" className="mt-3">
								Skip
							</AwesomeButton>
							<AwesomeButton onPress={onCouponSubmit} type="primary" className="mt-3">
								{couponValidityStatus === "requested" || couponFetchStatus === "requested" ? (
									<div class="spinner-border text-light" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								) : null}
								Submit
							</AwesomeButton>
						</div>
					</Form>
				</ModalBody>
			</Modal>
		</>
	);
}

export default ApplyCoupon;
