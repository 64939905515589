import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { CardBody } from "reactstrap";
import { IoFastFoodOutline, IoBus, IoTimeOutline } from "react-icons/io5";
import { IoIosCalendar } from "react-icons/io";
import { HiOutlineUserGroup, HiOutlineTrash } from "react-icons/hi";
import { GoPackage } from "react-icons/go";
import DeleteCartItemModal from "./DeleteCartItemModal";

const CartBodyList = ({ booking = false }) => {
  const [cookies, setCookie] = useCookies(["cart"]);

  const [cart, setCart] = useState(() => []);
  const [cartItemToDelete, setCartItemToDelete] = useState("");

  const { packages, bookingPackageStatus, couponValidityData } = useSelector(
    (state) => ({
      packages: state.discover.packages || [],
      bookingPackageStatus: state.discover.bookingPackageStatus,
      couponValidityData: state.discover.couponValidityData,
    })
  );

  useEffect(() => {
    if (bookingPackageStatus === "success") {
      setCart([]);
    }
  }, [bookingPackageStatus]);

  useEffect(() => {
    if (!cookies.cart?.packages?.length) {
      setCart([]);
      return;
    }
    setCart(cookies.cart?.packages || []);
  }, [cookies.cart]);

  const getPackageDetails = useCallback(
    (id) => {
      return packages.find((item) => item.id === id) || null;
    },
    [packages]
  );

  const couponDiscountData = useCallback(
    (id) => {
      const packData =
        couponValidityData?.find((item) => item.package_id === id) || null;
      return packData;
    },
    [couponValidityData]
  );

  const removeCartItem = () => {
    let _packages = cart.filter(
      (_, _index) => Number(cartItemToDelete) !== _index
    );
    setCookie(
      "cart",
      {
        ...cookies.cart,
        packages: _packages,
      },
      {
        path: "/",
      }
    );
    // setCart(_packages);
    setCartItemToDelete("");
  };

  const onDeleteItemClicked = (index) => {
    setCartItemToDelete(`${index}`);
  };

  const getFormattedDate = (_date) => {
    const formatted = moment(_date).format("DD MMM, 0YY");
    if (formatted === "Invalid date") return "";
    return formatted;
  };

  return (
    <>
      <CardBody
        style={{
          height: booking ? "fit-content" : "auto",
          overflowY: "auto",
        }}
      >
        {cart.length === 0 ? (
          <div className="h-100 w-100 d-flex align-items-center justify-content-center">
            <h5 className="text-muted">No items in the cart.</h5>
          </div>
        ) : (
          cart.map((cartItem, index) => (
            <div key={"cart" + index} className="cart__item pt-3 mb-3">
              <div className="d-flex justify-content-between">
                <p className="item__title px-2">{cartItem.package_name}</p>
              </div>
              <hr />
              <div className="d-flex justify-content-between px-2">
                {getFormattedDate(cartItem.date) != "" && (
                  <div className="d-flex justify-content-start">
                    <span className="my-2 me-2 item__details">
                      <IoIosCalendar size={16} />
                    </span>
                    <span className="my-2 item__details">
                      {getFormattedDate(cartItem.date)}
                    </span>
                  </div>
                )}
                {cartItem.time_slot_data && (
                  <div className="d-flex justify-content-start">
                    <span className="my-2 me-2 item__details">
                      <IoTimeOutline size={16} />
                    </span>
                    <span className="my-2 item__details">
                      {cartItem.time_slot_data?.value}
                    </span>
                  </div>
                )}
              </div>
              <hr />
              {(cartItem.adult_qty > 0 || cartItem.child_qty > 0) && (
                <div className="d-flex justify-content-between px-2">
                  {cartItem.adult_qty > 0 && (
                    <div className="d-flex justify-content-start">
                      <span className="my-2 me-2 item__details">
                        <i class="fa fa-user" aria-hidden="true"></i>
                      </span>
                      <span className="my-2 item__details">
                        Adult {cartItem.adult_qty}
                      </span>
                    </div>
                  )}
                  {cartItem.child_qty > 0 && (
                    <div className="d-flex justify-content-start">
                      <span className="my-2 me-2 item__details">
                        <i class="fa fa-child" aria-hidden="true"></i>
                      </span>
                      <span className="my-2 item__details">
                        Child {cartItem.child_qty}
                      </span>
                    </div>
                  )}
                </div>
              )}
              {cartItem.meals_qty?.[0] && (
                <div className="d-flex justify-content-between px-2">
                  <div className="d-flex justify-content-start">
                    <span className="my-2 me-2 item__details">
                      <IoFastFoodOutline size={16} />
                    </span>
                    {cartItem.meal_label.map((meal, i) => (
                      <span
                        className="my-2 item__details"
                        style={{
                          marginLeft:
                            cartItem.meal_label.length === i + 1 && "3px",
                        }}
                      >
                        (<span style={{ fontStyle: "italic" }}>Qty</span>:{" "}
                        {cartItem.meals_qty[i]}) {meal}{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {cartItem.meal_label.length > i + 1 && "|"}
                        </span>
                      </span>
                    ))}
                  </div>
                </div>
              )}
              {cartItem.subpackages_id?.length && (
                <div className="d-flex justify-content-between px-2">
                  <div className="d-flex justify-content-start">
                    <span className="my-2 me-2 item__details">
                      <GoPackage size={16} />
                    </span>
                    {cartItem.sub_package_label.map((subPackage, i) => (
                      <span
                        className="my-2 item__details"
                        style={{
                          marginLeft:
                            cartItem.sub_package_label.length === i + 1 &&
                            "3px",
                        }}
                      >
                        {subPackage}{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {cartItem.sub_package_label.length > i + 1 && "|"}
                        </span>
                      </span>
                    ))}
                  </div>
                </div>
              )}
              {cartItem.transport_id?.length && (
                <div className="d-flex justify-content-between px-2">
                  <div className="d-flex justify-content-start">
                    <span className="my-2 me-2 item__details">
                      <IoBus size={16} />
                    </span>
                    <span className="my-2 item__details">
                      {cartItem.transport_name}{" "}
                      {cartItem.transport_type ? "One Way" : "Two Way"}
                    </span>
                  </div>
                </div>
              )}
              <hr />
              <div className="d-flex justify-content-between px-2">
                <p className="my-2 item__details">
                  Total: &nbsp; AED{" "}
                  {couponDiscountData(cartItem.package_id)?.total ||
                    cartItem.total}
                  {couponDiscountData(cartItem.package_id)?.deducted_value &&
                  couponDiscountData(cartItem.package_id)?.deducted_value >
                    0 ? (
                    <>
                      &nbsp;
                      <span style={{ color: "#008a12" }}>
                        <b>
                          (AED{" "}
                          {cartItem.total -
                            couponDiscountData(cartItem.package_id)?.total}{" "}
                          discounted)
                        </b>
                      </span>
                    </>
                  ) : null}
                </p>
                {booking ? null : (
                  <span>
                    <button
                      onClick={() => onDeleteItemClicked(index)}
                      className="pointer btn btn-outline-danger btn-sm"
                    >
                      <HiOutlineTrash size={16} />
                    </button>
                  </span>
                )}
              </div>
            </div>
          ))
        )}
      </CardBody>
      <DeleteCartItemModal
        isOpen={!!cartItemToDelete}
        toggleOpen={() => setCartItemToDelete("")}
        onConfirm={removeCartItem}
      />
    </>
  );
};

export default CartBodyList;
