import React, { useState } from "react";
import { Input } from "reactstrap";
import { AwesomeButton } from "react-awesome-button";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-awesome-button/dist/themes/theme-blue.css";
import { addComment } from "../store/actions/discoverActions";

function Comments({ purchaseNumber, handleDetails }) {
  const [comment, setComment] = useState("");
  const { token, profileFetchStatus } = useSelector(({ authUser }) => ({
    token: authUser.uid,
  }));

  const onCommentSubmit = () => {
    addComment(
      token,
      purchaseNumber,
      comment,
      (resp) => {
        toast.success(resp.msg);
        setComment("");
        handleDetails();
      },
      (err) => {
        toast.error(err.message || "Could not add Internal Notes.");
      }
    );
  };

  return (
    <div>
      <Input
        placeholder="comment"
        value={comment}
        onChange={(e) => {
          setComment(e.target.value);
        }}
        type="text"
        style={{ width: 400, marginTop: "1rem" }}
        required
      />
      <AwesomeButton
        onPress={onCommentSubmit}
        type="primary"
        className="mt-3 w-40"
      >
        Add Comment
      </AwesomeButton>
    </div>
  );
}

export default Comments;
