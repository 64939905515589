import React, { useEffect, useMemo, useState } from "react";
import { IoHandLeft, IoSearchSharp } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import {
  Col,
  Nav,
  NavItem,
  Offcanvas,
  Row,
  Input,
  Modal,
  ModalBody,
  Form,
} from "reactstrap";
import { FETCH_PROFILE_SUCCESS } from "../store/types";
import logo from "../assets/images/pos-logo.jpg";
import { useDebouncedCallback } from "use-debounce";
import { useSelector } from "react-redux";
import {
  searchPackages,
  getSessionActiveStatus,
} from "../store/actions/discoverActions";
import { useCookies } from "react-cookie";
import { logout, getBalanceData } from "../store/actions/authActions";
import { AwesomeButton } from "react-awesome-button";
import ClearCartModal from "./ClearCartModal";
import RedeemOrderModal from "./RedeemOrderModal";
import NewCustomerForm from "./NewCustomerForm";

function Header() {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [activeNav, setActiveNav] = useState("welcome");
  const [searchTerm, setSearchTerm] = useState("");
  const [isClearOpen, setClearOpen] = useState(false);
  const [isRedeemOpen, setRedeemOpen] = useState(false);
  const [isLogoutOpen, setLogoutOpen] = useState(false);
  const [balance, setBalance] = useState("0.00");
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [cookies, setCookies, _] = useCookies(["cart"]);
  const [newFormOpen, setNewFormOpenState] = useState(false);
  const [removeUserOpen, setRemoveUserOpen] = useState(false);
  const isHomepage = useMemo(
    () => location.pathname === "/b2bagents",
    [location.pathname]
  );
  const toggleClearCart = () => [setClearOpen(!isClearOpen)];
  const toggleRedeemOpen = () => [setRedeemOpen(!isRedeemOpen)];
  const { token, userProfile } = useSelector(({ authUser }) => ({
    token: authUser.uid,
    userProfile: authUser.userDetails,
  }));
  const collapseToggle = () => {
    setCollapseOpen(!collapseOpen);
  };

  const search = useDebouncedCallback((_searchTerm) => {
    dispatch(searchPackages(_searchTerm));
  }, 800);

  const toggleNewOpen = () => {
    setNewFormOpenState(!newFormOpen);
  };

  const userHasData = useMemo(() => {
    return userProfile.name?.length || userProfile.mobile?.length;
  }, [userProfile.name, userProfile.mobile]);

  useEffect(() => {
    getBalanceData(token, (response) => setBalance(response.credit_left));
    const sessionStatus = () => {
      getSessionActiveStatus(
        token,
        (response) => console.log(response),
        () => onLogout()
      );
    };
    sessionStatus();
    const interval = setInterval(() => sessionStatus(), 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    search(searchTerm);
    // eslint-disable-next-line
  }, [searchTerm]);

  const onLogout = () => {
    setCookies("cart", { packages: [], coupon_id: "" }, { path: "/" });
    dispatch(logout());
    localStorage.removeItem("auth");
    history.replace("/");
  };

  const handleRemoveUserProfile = () => {
    dispatch({
      type: FETCH_PROFILE_SUCCESS,
      payload: [],
    });
    setRemoveUserOpen(false);
  };

  return (
    <>
      <div className="custom__navbar text-white">
        <div className="custom__width d-flex align-items-center">
          <p className="menu m-0 ms-5 page__title">
            <NavLink to="/">
              <img
                src="https://iili.io/htvSXS.png"
                style={{
                  width: "80px",
                  marginTop: "-10px",
                  marginRight: "15px",
                }}
              />
            </NavLink>
          </p>
          {isHomepage ? (
            <div className="d-flex ps-4 align-items-center">
              <IoSearchSharp style={{ color: "black" }} />
              <Input
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: 400, height: 40 }}
                placeholder="Search packages..."
                className="search__field"
              />
            </div>
          ) : null}
          <ul className="menu__header m-0 p-0 d-flex ms-auto me-2 gap-3">
            {/* <li>
              <NavLink to="/">
                <AwesomeButton className="header_menu__item">
                  Products
                </AwesomeButton>
              </NavLink>
            </li>
            <li>
              <AwesomeButton
                onPress={toggleRedeemOpen}
                className="header_menu__item"
              >
                Redeem
              </AwesomeButton>
            </li>
            <li>
              <NavLink to="/find">
                <AwesomeButton className="header_menu__item">
                  Search User
                </AwesomeButton>
              </NavLink>
            </li> */}
            {(userProfile.name?.length || userProfile.mobile?.length) && (
              <li>
                <div
                  className={`cart__item d-flex align-items-center justify-content-between p-3 w-100`}
                  style={{ height: "48px" }}
                >
                  <NavLink className="user_name_link" to="#">
                    <p className="user__name mb-0">
                      {userProfile.name?.length
                        ? userProfile.name
                        : userProfile.mobile}
                    </p>
                    <span onClick={() => setRemoveUserOpen(true)}>
                      <i class="fa fa-times-circle" aria-hidden="true"></i>
                    </span>
                  </NavLink>
                </div>
              </li>
            )}
            <li>
              <NavLink to="#">
                <AwesomeButton
                  onPress={toggleNewOpen}
                  className="header_menu__item"
                >
                  New Customer
                </AwesomeButton>
              </NavLink>
            </li>

            <li>
              <NavLink to="/history">
                <AwesomeButton className="header_menu__item">
                  My Account
                </AwesomeButton>
              </NavLink>
            </li>

            {/* <li>
              <AwesomeButton
                className="header_menu__item"
                onPress={toggleClearCart}
              >
                New Cart
              </AwesomeButton>
            </li> */}
            <li>
              <NavLink to="/credit-history">
                <AwesomeButton className="header_menu__item">
                  AED {balance}
                </AwesomeButton>
              </NavLink>
            </li>
            <li>
              <NavLink to="/checkout">
                <div
                  style={{
                    border: "1px solid",
                    borderRadius: "5px",
                    padding: "0.7rem",
                    backgroundColor: "black",
                    fontWeight: "bold",
                    display: "flex",
                    cursor: "pointer",
                    gap: "0.5rem",
                  }}
                  className="cart__icon"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    style={{
                      height: "20px",
                      marginLeft: "1rem",
                      fill: "white",
                    }}
                  >
                    <path d="M112 112C112 50.14 162.1 0 224 0C285.9 0 336 50.14 336 112V160H400C426.5 160 448 181.5 448 208V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V208C0 181.5 21.49 160 48 160H112V112zM160 160H288V112C288 76.65 259.3 48 224 48C188.7 48 160 76.65 160 112V160zM136 256C149.3 256 160 245.3 160 232C160 218.7 149.3 208 136 208C122.7 208 112 218.7 112 232C112 245.3 122.7 256 136 256zM312 208C298.7 208 288 218.7 288 232C288 245.3 298.7 256 312 256C325.3 256 336 245.3 336 232C336 218.7 325.3 208 312 208z" />
                  </svg>
                  <span className="cart__number">
                    {" "}
                    {cookies.cart ? cookies.cart.packages.length : 0}
                  </span>
                  <span style={{ marginRight: "1rem", color: "white" }}>
                    Cart
                  </span>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink to="#">
                <div
                  onClick={() => setLogoutOpen(true)}
                  className="menu__logout"
                  style={{
                    border: "1px solid",
                    borderRadius: "5px",
                    padding: "0.7rem",
                    backgroundColor: "black",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  <span style={{ margin: "0.5rem", color: "white" }}>
                    Logout
                  </span>
                </div>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <ClearCartModal isOpen={isClearOpen} toggleOpen={toggleClearCart} />
      <RedeemOrderModal isOpen={isRedeemOpen} toggleOpen={toggleRedeemOpen} />
      <Modal
        centered
        isOpen={isLogoutOpen}
        toggle={() => setLogoutOpen(false)}
        size="md"
      >
        <ModalBody className="px-5 py-4">
          <h4 className="mb-4">Are you sure you want to logout?</h4>

          <div className="d-flex gap-2">
            <AwesomeButton
              onPress={onLogout}
              className="header_menu__item mt-3 danger w-50"
            >
              Yes, Logout
            </AwesomeButton>
            <AwesomeButton
              onPress={() => setLogoutOpen(false)}
              className="auth-btn mt-3 w-50"
            >
              No, Stay
            </AwesomeButton>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        centered
        isOpen={removeUserOpen}
        toggle={() => setRemoveUserOpen(false)}
        size="md"
      >
        <ModalBody className="px-5 py-4">
          <h4 className="mb-4">Are you sure you want to remove customer ?</h4>

          <div className="d-flex gap-2">
            <AwesomeButton
              onPress={handleRemoveUserProfile}
              className="header_menu__item mt-3 danger w-50"
            >
              Yes
            </AwesomeButton>
            <AwesomeButton
              onPress={() => setRemoveUserOpen(false)}
              className="auth-btn mt-3 w-50"
            >
              No
            </AwesomeButton>
          </div>
        </ModalBody>
      </Modal>
      <NewCustomerForm
        isOpen={newFormOpen}
        toggleNewUserDetails={toggleNewOpen}
      />
      <Offcanvas
        isOpen={collapseOpen}
        toggle={collapseToggle}
        className="sidebar__collapse text-white"
        direction="end"
      >
        <div className="sidebar__collapse-child">
          <Nav vertical className="mt-3 custom__nav">
            <div className="text-center image__container">
              <img src={logo} alt="" className="img-fluid logo" />
            </div>
            {/* <NavItem className={`mt-5 ${activeNav == "packages" && "custom__nav__active"}`}>
							<Row>
								<Col xs="2">
									<i className="far fa-star"></i>
								</Col>
								<Col>
									<NavLink to="#" onClick={() => setActiveNav("packages")}>
										Packages
									</NavLink>
								</Col>
							</Row>
						</NavItem>
						<NavItem className={`${activeNav == "verifyorder" && "custom__nav__active"}`}>
							<Row>
								<Col xs="2">
									<i className="fa fa-mouse-pointer"></i>
								</Col>
								<Col>
									<NavLink to="#" onClick={() => setActiveNav("verifyorder")}>
										Verify Order
									</NavLink>
								</Col>
							</Row>
						</NavItem>
						<NavItem className={`${activeNav == "productivity" && "custom__nav__active"}`}>
							<Row>
								<Col xs="2">
									<i className="fa fa-briefcase"></i>
								</Col>
								<Col>
									<NavLink to="#" onClick={() => setActiveNav("productivity")}>
										My Order History
									</NavLink>
								</Col>
							</Row>
						</NavItem>
						<NavItem className={`${activeNav == "privacy" && "custom__nav__active"}`}>
							<Row>
								<Col xs="2">
									<i className="fa fa-users"></i>
								</Col>
								<Col>
									<NavLink to="#" onClick={() => setActiveNav("privacy")}>
										Customer Profile
									</NavLink>
								</Col>
							</Row>
						</NavItem> */}

            <NavItem
              className={`${
                activeNav == "accessible" && "custom__nav__active"
              }`}
            >
              <Row>
                <Col xs="2">
                  <i className="far fa-comment-alt"></i>
                </Col>
                <Col>
                  <NavLink
                    to=""
                    onClick={() =>
                      window.open(
                        "https://docs.google.com/forms/d/e/1FAIpQLSc67FGD0ijxzqA9DKBDgCDMdHt10PHZ9LcFRPN6hmyxtZgIVw/viewform",
                        "_blank",
                        "toolbar,scrollbars,resizable,top=500,left=500,width=1000,height=1000"
                      )
                    }
                  >
                    Issues / Suggestions
                  </NavLink>
                </Col>
              </Row>
            </NavItem>
            <NavItem
              onClick={onLogout}
              className={`${activeNav == "getstart" && "custom__nav__active"}`}
            >
              <Row>
                <Col xs="2">
                  <i className="fa fa-lock"></i>
                </Col>
                <Col>
                  <NavLink to="#">Logout</NavLink>
                </Col>
              </Row>
            </NavItem>
          </Nav>
        </div>
      </Offcanvas>
    </>
  );
}

export default Header;
