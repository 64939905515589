import React from "react";
import { AwesomeButton } from "react-awesome-button";
import { Modal, ModalBody } from "reactstrap";

const DeleteCartItemModal = ({ isOpen, toggleOpen, onConfirm }) => {
	const onClear = () => {
		onConfirm && onConfirm();
	};

	const onCancel = () => {
		toggleOpen();
	};

	return (
		<Modal unmountOnClose centered isOpen={isOpen} toggle={toggleOpen} size="md">
			<ModalBody className="px-5 py-4">
				<h4 className="mb-4">Are you sure you want to delete this item from the cart?</h4>

				<div className="d-flex gap-2 cart__clear-buttons">
					<AwesomeButton onPress={onCancel} type="secondary" className="mt-3 w-50">
						No, cancel!
					</AwesomeButton>
					<AwesomeButton onPress={onClear} className="mt-3 danger w-50">
						Yes, delete from cart.
					</AwesomeButton>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default DeleteCartItemModal;
