import React, { useEffect } from "react";

const BookingClose = () => {
	useEffect(() => {
		window.close();
	}, []);

	return <div>Purchase Finished.</div>;
};

export default BookingClose;
