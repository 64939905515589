import React, { useEffect } from "react";

const CloseWindow = () => {
  useEffect(() => {
    window.close("Payment - Discover POS");
  }, []);

  return <div>Purchase Finished.</div>;
};

export default CloseWindow;
