import React, { useState, useCallback } from "react";
import { useCookies } from "react-cookie";
import {
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { getCountryCallingCode } from "react-phone-number-input";
import { getUserProfile } from "../store/actions/authActions";
import { AwesomeButton } from "react-awesome-button";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import countryList from "react-select-country-list";

import "react-awesome-button/dist/themes/theme-blue.css";

function NewCustomerForm(props) {
  let { isOpen, toggleNewUserDetails } = props;
  const [, setCookies] = useCookies("cart");

  const { token, profileFetchStatus } = useSelector(({ authUser }) => ({
    token: authUser.uid,
    profileFetchStatus: authUser.profileFetchStatus,
  }));

  const dispatch = useDispatch();

  const [localUserProfile, setLocalUserProfile] = useState({
    mobile: "",
  });
  const [countryCode, setCountryCode] = useState("AE");

  const updateUserLocalProfile = (field, value) => {
    setLocalUserProfile({ ...localUserProfile, [field]: value });
  };

  const onPhoneSubmit = () => {
    if (!localUserProfile.mobile.length) {
      alert("Please enter the phone number.");
      return;
    }
    if (!countryCode.length) {
      alert("Please choose your country.");
      return;
    }
    const countryPhoneCode = getCountryCallingCode(countryCode);

    return dispatch(
      getUserProfile(
        `${countryPhoneCode}${localUserProfile.mobile}`,
        token,
        () => {
          toast.success("Profile added.", {
            position: "top-center",
          });
          toggleNewUserDetails();
          // setCookies(
          //   "cart",
          //   {
          //     packages: [],
          //     coupon: null,
          //     coupon_id: "",
          //   },
          //   {
          //     path: "/",
          //   }
          // );
        }
      )
    );
  };

  const countryCodeAsync = useCallback((countryCode) => {
    try {
      return getCountryCallingCode(countryCode);
    } catch (err) {
      return "000";
    }
  }, []);

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={toggleNewUserDetails} size="lg">
        <ModalHeader toggle={toggleNewUserDetails}>
          New Customer Details
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Label className="mt-2">Phone Number</Label>
            <InputGroup>
              <InputGroupText>
                <Input
                  style={{ width: 275 }}
                  value={countryCode}
                  type="select"
                  onChange={(e) => {
                    setCountryCode(e.target.value);
                  }}
                >
                  {countryList()
                    .getData()
                    .map((item) => (
                      <option key={item.value} value={item.value}>
                        +{countryCodeAsync(item.value)} - {item.label}
                      </option>
                    ))}
                </Input>
              </InputGroupText>
              <Input
                placeholder="5678567890"
                value={localUserProfile.mobile}
                onChange={(e) => {
                  updateUserLocalProfile("mobile", e.target.value);
                }}
                type="tel"
                required
              />
            </InputGroup>

            <AwesomeButton
              onPress={onPhoneSubmit}
              type="primary"
              className="mt-3 w-100"
            >
              {profileFetchStatus === "requested" ? (
                <div class="spinner-border text-light" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : null}
              Submit
            </AwesomeButton>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default NewCustomerForm;
