import axios from "axios";
import {
  BOOKING_CANCELLED,
  BOOK_PACKAGE_FAILED,
  BOOK_PACKAGE_REQUESTED,
  BOOK_PACKAGE_SUCCESS,
  COUPON_SKIPPED,
  COUPON_VALIDITY_FAILED,
  COUPON_VALIDITY_REQUESTED,
  COUPON_VALIDITY_SUCCESS,
  FETCH_COUPONS_FAILED,
  FETCH_COUPONS_REQUESTED,
  FETCH_COUPONS_SUCCESS,
  FETCH_DATE_AVAILABLE,
  FETCH_PACKAGE,
  FETCH_PACKAGES,
  FETCH_PACKAGE_SLOTS,
  PACKAGES_FILTERED,
  PAYMENT_FAILED,
  PAYMENT_REQUESTED,
  PAYMENT_SUCCESS,
  SWITCH_TAG,
} from "../types";

import { toast } from "react-toastify";

export const getPackages = (token) => async (dispatch) => {
  let packageData = new FormData();
  packageData.append("action", "b2bagent_packages");
  // packageData.append("slug", "DiscoverMleiha");
  axios
    .post("https://bo.discovershurooq.ae/api/pos_api.php", packageData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      let _tags = [];
      for (let _pack of res.data.data) {
        if (_pack.tags) _tags.push(..._pack.tags.split(","));
      }

      const __tags = new Set(_tags);
      _tags = [];
      __tags.forEach((_tag) => {
        if (_tag.length) _tags.push(_tag);
      });
      console.log({ tag: _tags[0] });
      dispatch({
        type: FETCH_PACKAGES,
        payload: {
          packages: res.data.data,
          tags: _tags,
        },
      });
    });
};

export const selectTag = (tagName) => (dispatch) => {
  dispatch({
    type: SWITCH_TAG,
    payload: tagName,
  });
};

export const searchPackages = (searchTerm) => (dispatch) => {
  dispatch({
    type: PACKAGES_FILTERED,
    payload: searchTerm,
  });
};

export const cancelBooking = () => (dispatch) => {
  dispatch({
    type: BOOKING_CANCELLED,
  });
};

export const skipCoupon = () => (dispatch) => {
  dispatch({
    type: COUPON_SKIPPED,
  });
};

export const getPackagesDetails = (packageData, token) => async (dispatch) => {
  axios
    .post("https://bo.discovershurooq.ae/api/pos_api.php", packageData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      console.log("res", res);
      dispatch({
        type: FETCH_PACKAGE,
        payload: res.data.data,
      });
    });
};

export const getPurchaseDetails =
  (purchase_number, token, onSuccess, onError) => async (dispatch) => {
    try {
      const form = new FormData();
      form.append("action", "purchaseDetails");
      form.append("purchase_number", purchase_number);

      const purchaseDetails = await axios.post(
        "https://bo.discovershurooq.ae/api/pos_api.php",
        form,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      onSuccess && onSuccess(purchaseDetails.data);
    } catch (err) {
      onError && onError(err);
    }
  };
export const getPurchaseHistory =
  (token, onSuccess, onError) => async (dispatch) => {
    try {
      const form = new FormData();
      form.append("action", "posUserHistory");

      const { data: purchaseHistory } = await axios.post(
        "https://bo.discovershurooq.ae/api/pos_api.php",
        form,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      onSuccess && onSuccess(purchaseHistory.data);
    } catch (err) {
      onError && onError(err);
    }
  };

export const fetchCouponsList = (token) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_COUPONS_REQUESTED,
    });

    const form = new FormData();
    form.append("action", "listCoupons");

    const { data } = await axios.post(
      "https://bo.discovershurooq.ae/api/pos_api.php",
      form,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch({
      type: FETCH_COUPONS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_COUPONS_FAILED,
    });
  }
};

export const couponValidity =
  (packageData, user_id, token, onSuccess) => async (dispatch) => {
    try {
      dispatch({
        type: COUPON_VALIDITY_REQUESTED,
      });

      const form = new FormData();
      form.append("action", "validateCoupon");
      form.append("items", JSON.stringify(packageData));
      form.append("user_id", user_id);

      const res = await axios.post(
        "https://bo.discovershurooq.ae/api/pos_api.php",
        form,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      dispatch({
        type: COUPON_VALIDITY_SUCCESS,
        payload: res.data.data.packages,
      });

      onSuccess && onSuccess();
    } catch (err) {
      dispatch({
        type: COUPON_VALIDITY_FAILED,
      });
    }
  };

export const createOrder =
  (packageData, user_id, token, onSuccess) => async (dispatch) => {
    try {
      dispatch({
        type: COUPON_VALIDITY_REQUESTED,
      });

      const form = new FormData();
      form.append("action", "createPurchase");
      form.append("items", JSON.stringify(packageData));
      form.append("user_id", "11507");

      const res = await axios.post(
        "https://bo.discovershurooq.ae/api/pos_api.php",
        form,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      dispatch({
        type: COUPON_VALIDITY_SUCCESS,
        payload: res.data.data.packages,
      });

      onSuccess && onSuccess();
    } catch (err) {
      dispatch({
        type: COUPON_VALIDITY_FAILED,
      });
    }
  };

export const authorizePayLater = async (user_id, token, onSuccess) => {
  const form = new FormData();
  form.append("action", "ifPayLaterUser");
  form.append("user_id", user_id);
  await axios
    .post("https://bo.discovershurooq.ae/api/pos_api.php", form, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      onSuccess && onSuccess(res.data.paylater_authorised);
    });
};

export const bookPackages =
  (
    notes,
    packageData,
    bookingMethod,
    bookingMethodNotes,
    user_id,
    token,
    onSuccess
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: BOOK_PACKAGE_REQUESTED,
      });

      const form = new FormData();
      form.append("action", "createPurchase");
      // form.append("bookingmethod_info", bookingMethod);
      // form.append("bookingmethod_notes", bookingMethodNotes);
      form.append(
        "items",
        JSON.stringify({ ...packageData, site: window.location.host })
      );
      form.append("user_id", user_id || "11507");
      form.append("site", window.location.host);
      form.append("channel", "B2B-Portal");
      if (notes.length) {
        form.append("internal_notes", notes);
      }

      const { data: orderResponse } = await axios.post(
        "https://bo.discovershurooq.ae/api/pos_api.php",
        form,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log({ orderResponse });
      if (!orderResponse.success) throw new Error(orderResponse.message);
      dispatch({
        type: BOOK_PACKAGE_SUCCESS,
        payload: orderResponse,
      });
      onSuccess && onSuccess(orderResponse);
    } catch (err) {
      toast.error(err.message);
      dispatch({
        type: BOOK_PACKAGE_FAILED,
      });
    }
  };
export const initiatePayment =
  (purchaseResponse, token, paymentMethod, onSuccess) => async (dispatch) => {
    try {
      dispatch({
        type: PAYMENT_REQUESTED,
      });
      const form = new FormData();
      form.append("action", "initiatePayment");
      form.append("purchase_number", purchaseResponse.purchase_number);
      form.append("purchase_id", purchaseResponse.id);
      form.append("payment_method", paymentMethod);
      form.append("redirect_to", `${window.location.origin}/closewindow`);

      const { data: orderResponse } = await axios.post(
        "https://bo.discovershurooq.ae/api/pos_api.php",
        form,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log({ orderResponse });
      if (!orderResponse.success) throw new Error(orderResponse.message);
      dispatch({
        type: PAYMENT_SUCCESS,
        payload: orderResponse,
      });
      onSuccess && onSuccess(orderResponse);
    } catch (err) {
      console.log({ _err: err });
      toast.error(err.message);
      dispatch({
        type: PAYMENT_FAILED,
      });
    }
  };

export const getPackagesSlots = (packageData) => async (dispatch) => {
  axios.post("https://api.discovershurooq.ae/api/", packageData).then((res) => {
    console.log("res", res);
    dispatch({
      type: FETCH_PACKAGE_SLOTS,
      payload: res.data.timeslots,
    });
  });
};

export const getDateAvailable = (packageData) => async (dispatch) => {
  axios.post("https://api.discovershurooq.ae/api/", packageData).then((res) => {
    console.log("timeslots", res.data);
    dispatch({
      type: FETCH_DATE_AVAILABLE,
      payload: res.data,
    });
  });
};

export const getBookingDetails = async (
  order_number,
  token,
  onSuccess,
  onError
) => {
  try {
    const form = new FormData();
    form.append("action", "bookingDetails");
    form.append("order_number", order_number);

    const { data: response } = await axios.post(
      "https://bo.discovershurooq.ae/api/pos_api.php",
      form,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    onSuccess && onSuccess(response);
  } catch (err) {
    onError(err);
  }
};

export const getCreditHistory = async (token, onSuccess) => {
  const form = new FormData();
  form.append("action", "getStatementHistory");

  const { data: response } = await axios.post(
    "https://bo.discovershurooq.ae/api/pos_api.php",
    form,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  onSuccess && onSuccess(response);
};

export const redeemOrder = async (order_number, token, onSuccess, onError) => {
  try {
    const form = new FormData();
    form.append("action", "redeemOrder");
    form.append("order_number", order_number);

    const { data: response } = await axios.post(
      "https://bo.discovershurooq.ae/api/pos_api.php",
      form,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    onSuccess && onSuccess(response);
  } catch (err) {
    onError(err);
  }
};

export const sendEmailDetail = async (
  email,
  purchaseNumber,
  token,
  onSuccess,
  onError
) => {
  try {
    const form = new FormData();
    form.append("action", "sendEmailByPurchaseNumber");
    form.append("purchase_number", purchaseNumber);
    form.append("email", email);

    const { data: response } = await axios.post(
      "https://bo.discovershurooq.ae/api/pos_api.php",
      form,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    onSuccess && onSuccess(response);
  } catch (err) {
    onError(err);
  }
};

export const getSessionActiveStatus = async (token, onSuccess, onError) => {
  const form = new FormData();
  form.append("action", "validateActiveSession");

  await axios
    .post("https://bo.discovershurooq.ae/api/pos_api.php", form, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const addComment = async (
  token,
  purchaseNumber,
  comment,
  onSuccess,
  onError
) => {
  const form = new FormData();
  form.append("action", "updateInternalNotes");
  form.append("purchase_number", purchaseNumber);
  form.append("internal_notes", comment);

  await axios
    .post("https://bo.discovershurooq.ae/api/pos_api.php", form, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((err) => {
      onError && onError(err);
    });
};
