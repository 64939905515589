import React, { useEffect, useState } from "react";
import { IoSearchSharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import { Input } from "reactstrap";
import { useDebouncedCallback } from "use-debounce";
import { AwesomeButton } from "react-awesome-button";
import UserSearchTable from "../components/UserSearchTable";
import { searchUser } from "../store/actions/authActions";

const FindUser = () => {
  const [mobile, setMobileNumber] = useState("");
  const [userData, setUserData] = useState({ status: "idle", list: [] });

  const token = useSelector((state) => state.authUser.uid);

  //   useEffect(() => {
  //     if (!mobile.length) {
  //       setUserData({ list: [], status: "idle" });
  //       return;
  //     }
  //     // eslint-disable-next-line
  //   }, [mobile]);

  const search = useDebouncedCallback(() => {
    if (!mobile.length) {
      setUserData({ list: [], status: "idle" });
      return;
    }
    setUserData({ list: [], status: "requested" });
    searchUser(
      mobile,
      token,
      (list) => {
        setUserData({ status: "success", list });
      },
      () => {
        setUserData({ status: "failed", list: [] });
      }
    );
  }, 100);

  return (
    <div className="payment__container mt-5 ps-5">
      <h5>Search Users</h5>
      <div
        className="d-flex align-items-center"
        style={{ borderBottom: "1px solid #333" }}
      >
        <IoSearchSharp />
        <Input
          value={mobile}
          onChange={(e) => setMobileNumber(e.target.value)}
          style={{ width: "100%", height: 40, color: "black" }}
          placeholder="Enter mobile number"
          className="search__field user"
        />
        <div class="input-group-append">
          <AwesomeButton size="small" onPress={search}>
            Search
          </AwesomeButton>
        </div>
      </div>
      <UserSearchTable userList={userData.list} status={userData.status} />
    </div>
  );
};

export default FindUser;
