import React, { useEffect, useMemo, useState, useRef } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { Modal, ModalBody, Input } from "reactstrap";
import sharjahLogo from "../assets/images/sharjah-logo.png";
import html2pdf from "html2pdf.js";
import {
  getBookingDetails,
  redeemOrder,
  sendEmailDetail,
} from "../store/actions/discoverActions";
import { AwesomeButton } from "react-awesome-button";
import QRCode from "react-qr-code";
import Comments from "../components/Comments";
import moment from "moment";

const RedeemBooking = ({
  match: {
    params: { booking_number },
  },
}) => {
  const [bookingDetails, setBookingDetails] = useState({
    status: "idle",
    data: null,
  });
  const [redeemed, setRedeemed] = useState([]);
  const [purchaseNumber, setPurchaseNumber] = useState("");
  const [openEmail, setOpenEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [imgSrc, setImgSrc] = useState();
  const [notes, setNotes] = useState([]);
  const componentRef = useRef();
  const { token, userDetails } = useSelector(({ authUser }) => ({
    token: authUser.uid,
    userDetails: authUser.userDetails,
  }));

  useEffect(() => {
    handleBookingDetails();
  }, []);

  useEffect(() => {
    const loadImage = async () => {
      try {
        const image = await import(
          `../assets/images/vendor-logos/${userDetails.name}.png`
        );
        setImgSrc(image);
      } catch (error) {
        console.error("Error loading image:", error);
      }
    };

    loadImage();
  }, [userDetails]);

  const handleBookingDetails = () => {
    getBookingDetails(
      booking_number,
      token,
      (resp) => {
        console.log(resp);
        setNotes(
          resp.data.purchase_data.internal_notes
            ? JSON.parse(resp.data.purchase_data.internal_notes)
            : []
        );
        setPurchaseNumber(
          resp.data.order_data.find(
            (item) => item.order_number === booking_number
          )?.purchase_number
        );
        setBookingDetails({
          status: "success",
          data: resp.data.order_data.find(
            (item) => item.order_number === booking_number
          ),
        });
      },
      (err) => {
        toast.error(err.message || "Failed to load data.");
        setBookingDetails({
          status: "failed",
          data: null,
        });
      }
    );
  };

  const onRedeem = (order_number) => {
    if (redeemed.includes(order_number)) {
      toast.info("Order already redeemed.");
      return;
    }

    redeemOrder(
      order_number,
      token,
      (resp) => {
        toast.success("Order redeemed.");
        setRedeemed([...redeemed, order_number]);
      },
      (err) => {
        toast.error(err.message || "Could not redeem. Retry.");
      }
    );
  };

  const order = useMemo(() => bookingDetails.data, [bookingDetails.data]);

  var pdfOption = {
    filename: `order-${order?.order_number}.pdf`,
    html2canvas: {
      dpi: 300,
      letterRendering: true,
      useCORS: true,
    },
  };
  const getStatus = useMemo(() => {
    if (!order) return "pending";
    if (order.status === "1") return "success";
    if (order.status === "2") return "failed";
    return "pending";
  }, [order]);

  // const meals = useMemo(() => {
  //   if (!order || !order.food.length) return [0, 0];
  //   return order.food.split(",");
  // }, [order]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    print: async (printIframe: HTMLIFrameElement) => {
      const document = printIframe.contentDocument;
      if (document) {
        document.getElementById("image").style.display = "block";
        document.getElementById("special-logo").style.display = "block";
        
        if (imgSrc)
          document.getElementById("sharjah-logo").style.display = "block";
        document.getElementById("gift-voucher").style.visibility = "visible";
        document.getElementById("comment").style.display = "none";
        document.getElementById("internal-notes").style.display = "none";
        document.getElementById("ticket").style.display = "none";
        document.getElementById("email").style.display = "none";
        if (order && bookingDetails.data?.redeem) {
          document.getElementById("redeem").style.display = "none";
        }
        const html = document.getElementsByTagName("html")[0];
        await html2pdf().from(html).set(pdfOption).save();
      }
    },
  });

  const handleSendEmail = () => {
    sendEmailDetail(
      email,
      purchaseNumber,
      token,
      (resp) => {
        console.log(resp);
        toast.success(resp.message);
      },
      (err) => {
        toast.error(err.message || "Failed to Send.");
      }
    );
  };

  return (
    <div className="payment__container mt-4" ref={componentRef}>
      <div
        className="w-100 pb-3 d-flex justify-content-between"
        style={{ borderBottom: "1px solid lightgrey" }}
      >
        {order ? (
          bookingDetails.data?.redeem ? (
            <div
              id="redeem"
              className="flex-column text-muted text-align-left"
              style={{ width: 220, fontSize: 14, display: "flex" }}
            >
              <div>
                Redeemd on : <b>{bookingDetails.data.redeemed_time}</b>
              </div>
              <div>
                Redeemd by user : <b>{bookingDetails.data.redeemed_by}</b>
              </div>
            </div>
          ) : null
        ) : // <AwesomeButton onPress={() => onRedeem(order.order_number)}>
        //   Redeem
        // </AwesomeButton>
        null}
        {imgSrc && (
          <img
            id="sharjah-logo"
            // src={`https://api.discovershurooq.ae/files/b2b-logos/${userDetails.name}.png`}
          src={require(`../assets/images/vendor-logos/${userDetails.name}.png`)}

 

            

            
            style={{
              marginTop: "40px",
              marginRight: "15px",
              width: "80px",
              height: "20px",
              objectFit: "contain",
               
              display: "none",
            }}
          />
        )}






        <h3
          id="gift-voucher"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            visibility: "hidden",
            fontSize: 32,
            fontWeight: "bold",
          }}
        >
          Ticket Voucher
        </h3>




        
         <img
            id="special-logo"
            src="https://iili.io/dKFWyTg.png"
            style={{
              marginTop: "0px",
              marginRight: "10px",
              width: "280px",
              height: "105px",
              objectFit: "contain",
              display: "none",
            }}
          />

        


        
        <img
          id="image"
          src="https://iili.io/htvSXS.png"
          style={{
            width: "100px",
            marginTop: "-10px",
            marginRight: "15px",
            display: "none",
          }}
        />
        <div id="email" style={{ display: "block" }}>
          <AwesomeButton
            className="payment__ticket"
            onPress={() => setOpenEmail(true)}
          >
            {" "}
            Email Ticket{" "}
          </AwesomeButton>
        </div>
        <div id="ticket" style={{ display: "block" }}>
          <AwesomeButton className="payment__ticket" onPress={handlePrint}>
            {" "}
            Download Ticket PDF{" "}
          </AwesomeButton>
        </div>
      </div>
      {bookingDetails.status === "success" ? (
        order ? (
          <div className="order__details pt-4">
            {/* <div className="order__details--header">
              <h5>{order.package_name}</h5>
            </div> */}
            <h4>{order?.package_name}</h4>

            <div className="order__description">
              <div>
                <div className="desc">
                  <span>Order ID:</span>
                  <span>{order.order_number}</span>
                </div>
                <div className="desc">
                  <span>Ordered on:</span>
                  <span>{moment(order.created_at).format("DD/MM/YYYY")}</span>
                </div>
                <div className="desc">
                  <span>Order Status</span>
                  <span>
                    <span className={`purchase__status ${getStatus}`}>
                      {getStatus}
                    </span>
                  </span>
                </div>
                <br />

                <div className="desc">
                  <span>Destination Name:</span>
                  <span>{order.destination_name}</span>
                </div>
                <div className="desc">
                  <span>Package Name:</span>
                  <span>{order.package_name}</span>
                </div>
                {order.order_date && (
                  <div className="desc">
                    <span>Date of Event:</span>
                    <span>{order.order_date}</span>
                  </div>
                )}
                {order.order_timeslot && (
                  <div className="desc">
                    <span>Time of Event:</span>
                    <span>{order.order_timeslot}</span>
                  </div>
                )}
                <br />

                {(Number(order.adult_qty) > 0 ||
                  Number(order.child_qty) > 0) && (
                  <div className="desc">
                    <span>Attendee(s):</span>
                    <span>
                      {Number(order.adult_qty) > 0 && (
                        <span>
                          {order.adult_qty} Adult{" "}
                          {Number(order.child_qty) > 0 && ","}
                        </span>
                      )}
                      {Number(order.child_qty) > 0 && (
                        <span>{order.child_qty} Child </span>
                      )}
                    </span>
                  </div>
                )}
                {order.transport_id !== "0" && (
                  <div className="desc">
                    <span>Transportation:</span>
                    <span>{order.transport_name}</span>
                  </div>
                )}
                {order.order_meals?.length > 0 && (
                  <div className="desc">
                    <span>Meal(s):</span>
                    {order.order_meals.map((meal, i) => (
                      <span>
                        {" "}
                        <span> {meal.meal_qty}</span>
                        <span> {meal.meal_name}</span>
                        {order.order_meals.length > i + 1 && ","}
                      </span>
                    ))}
                  </div>
                )}
                {(order.order_sub_packages?.length > 0 ||
                  order.subpackages_adult_qty?.length > 0 ||
                  order.subpackages_child_qty?.length > 0) && (
                  <div className="desc">
                    <span>Addon(s):</span>
                    <span>
                      {order.order_sub_packages?.length > 0 && (
                        <span>
                          {order.order_sub_packages?.length} packages,{" "}
                        </span>
                      )}
                      {order.subpackages_adult_qty.length > 0 && (
                        <span>
                          {order.subpackages_adult_qty.split(",").length}adult,
                        </span>
                      )}
                      {order.subpackages_child_qty.length > 0 && (
                        <span>
                          {order.subpackages_child_qty.split(",").length}child
                        </span>
                      )}
                    </span>
                  </div>
                )}
                <br />
                {/* <img
                  id="sharjah-tourism-logo"
                  src="https://iili.io/wWytg2.png"
                  style={{
                    height: "250px",
                    marginTop: "-10px",
                    marginRight: "15px",
                    display: "none",
                  }}
                /> */}
                <div className="mt-4" id="internal-notes">
                  <h5>Internal Notes(s)</h5>
                  {notes.map((note, i) => (
                    <div style={{ marginLeft: "1rem" }}>{note.comment}</div>
                  ))}
                </div>
                <div id="comment" style={{ display: "block" }}>
                  <Comments
                    purchaseNumber={order?.purchase_number}
                    handleDetails={handleBookingDetails}
                  />
                </div>
                {/* <div className="desc">
                  <span>
                    <h6 className="text-muted">Total Amount:</h6>
                  </span>
                  <span>
                    <b>AED {order.total}</b>
                  </span>
                </div> */}
              </div>
              <div className="order__qr">
                <QRCode value={order.order_number} size={200} />
              </div>
            </div>
          </div>
        ) : (
          <div className="order__error">
            <h6>Could not find order data.</h6>
          </div>
        )
      ) : (
        "loading..."
      )}
      <Modal
        unmountOnClose
        centered
        isOpen={openEmail}
        toggle={() => setOpenEmail(false)}
        size="md"
      >
        <ModalBody className="px-5 py-4">
          <h4 className="mb-4">Email</h4>
          <Input
            type="email"
            placeholder="your email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <AwesomeButton className="mt-3 w-100" onPress={handleSendEmail}>
            Send
          </AwesomeButton>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default withRouter(RedeemBooking);
